import { t } from "i18next";
import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { setActivePage, setDialog, toggleDialog, toggleLoading } from "../../store/appSlice";

import { useState } from "react";
import ContentContainer from "../../components/ContentContainer";
import InputText, { InputTextState } from "../../components/InputText";
import ToggleButtonSmall from "../../components/ToggleButtonSmall";
import SvgUploadIconNew from "../../assets/IconComponents/UploadIconNew";
import StayDiscountPopup from "../../components/Account/StayDiscountPopup";
import WarningMessage from "../../components/WarningMessage";
import SvgCelebrationIcon from "../../assets/IconComponents/CelebrationIcon";
import SvgThumbsUpTritoneIcon from "../../assets/IconComponents/ThumbsUpTritoneIcon";
import RadioSelector from "../../components/RadioSelector/RadioSelector";
import TextView from "../../components/TextView";
import { ApiResponse, DeleteUserRequest, useDeleteUserMutation, useFreezeUserMutation, useLazyUserSettingsQuery, useUpdateOwnPasswordMutation, useUpdateUserSettingsMutation, useUserSettingsQuery } from "../../services/UsertApi";
import { useCancelSubscriptionMutation } from "../../services/PaymentApi";
import { IGenericSelectorData } from "../../components/GenericSelector/GenericSelector";
import { FreezeUserRequest } from "../../services/UsertApi";
import { Utils } from "../../helpers/Utils";
import PlanEditPopupOpener from "../PlanPage/PlanEditPopup";
import { TextUtils } from "../../helpers/TextUtils";
import EditAddressPopupOpener from "./EditAddress/EditAddressPopup";
import { useLazyLogoutQuery } from "../../services/AuthenticationApi.old/AuthenticationApi";
import { setAccessToken, setLastSelectedBrandId, setRefreshToken, toggleLoggedIn } from "../../store/persistedSlice";
import { setNewContent } from "../../store/contentSlice";
import ConfirmationPopupOpener from "../../components/ConfirmationPopup/ConfirmationPopup";
import { Add, ThumbsUp } from "../../assets/IconComponents";
import EditCreditCardPopupOpener from "./EditCreditCardPopup";
import SvgEyeIcon from "../../assets/IconComponents/EyeIcon";
import SvgEyeCrossedIcon from "../../assets/IconComponents/EyeCrossed";
import AddSocialAccountsPopupOpener from "./SocialAccount/AddSocialAccountPopup";
import SocialAccountsEmpty from "./SocialAccount/SocialAccountsEmpty";
import SocialAccountListing from "./SocialAccount/SocialAcountListing";
import { v4 as uuidv4 } from "uuid";
import { Button } from "antd";

interface BrandHubPageProps { }

interface BrandModel {
  name: string;
}

const AccountSettingsPage: FunctionComponent<BrandHubPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const view = urlParams.get('view');

  const [showCreditCardUpdateSuccessPopup, setShowCreditCardUpdateSuccessPopup] = useState<boolean>(false)
  const [showCreditCardDialog, setShowCreditCardDialog] = useState<boolean>(false)
  const [showAddressUpdateSuccessPopup, setShowAddressUpdateSuccessPopup] = useState(false);
  const [showPlanUpdateSuccessPopup, setShowPlanUpdateSuccessPopup] = useState(false);
  const [showAddressEditDialog, setShowAddressEditDialog] = useState(false);
  const [showAddSocialAccountsDialog, setShowAddSocialAccountsDialog] = useState(false);
  const [toggleState, setToggleState] = useState("account")
  const [showStayDiscountPopup, setShowStayDiscountPopup] = useState(false);
  const [toggleButtonChecked, setToggleButtonChecked] = useState(false);
  const [isUpgradePlanPopupOpen, setIsUpgradePlanPopupOpen] = useState(false);
  const [deleteExplanation, setDeleteExplanation] = useState("");

  const [
    logoutUser,
    {
      isLoading: isLogoutLoading,
      isSuccess: isLogoutSuccess,
      isError: isLogoutError,
    },
  ] = useLazyLogoutQuery();
  const [getUser, { isLoading: userSettingsLoading, isFetching: userSettingsFetching, data: userSettings }] = useLazyUserSettingsQuery();
  const [updateUserSettings, { isLoading: userSettingsUpdating }] = useUpdateUserSettingsMutation();
  const [cancelSubscription, { isLoading: userCancelSubscriptionLoading }] =
    useCancelSubscriptionMutation();
  const [deleteUser, { isLoading: userDeleting }] =
    useDeleteUserMutation();
  const [oldPasswordField, setOldPasswordField] = useState("");
  const [newPasswordField, setNewPasswordField] = useState("");
  const [newPasswordRepeatField, setNewPasswordRepeatField] = useState("");
  const [effectiveAt, setEffectiveAt] = useState("");
  const [listingKey, setListingKey] = useState("");

  const [freezeUser, { isLoading: userFreezing }] = useFreezeUserMutation();
  const [changePassword, { isLoading: userPasswordChanging, isSuccess: userPasswordSuccessfullyChanged }] = useUpdateOwnPasswordMutation();
  const [reasonToDelete, setReasonToDelete] = useState<IGenericSelectorData>({
    id: "TooExpensive",
    title: "Too Expensive",
  });

  const deleteAccountReasonsItems: IGenericSelectorData[] = [
    { id: "TooExpensive", title: "Too Expensive" },
    { id: "DifficultToUse", title: "Difficult To Use" },
    { id: "MissingFeatures", title: "Missing Features" },
    { id: "LowerThanExpected", title: "Quality Is Lower Than Expected" },
    { id: "PoorSupport", title: "Poor Support" },
  ];

  const [warningMessage, setWarningMessage] = useState("");

  useEffect(() => {
    dispatch(
      toggleLoading(
        userSettingsLoading ||
        userSettingsFetching ||
        userSettingsUpdating ||
        userCancelSubscriptionLoading ||
        userFreezing ||
        userPasswordChanging ||
        isLogoutLoading
      )
    );
  }, [
    userSettingsLoading,
    userSettingsFetching,
    userSettingsUpdating,
    userCancelSubscriptionLoading,
    userFreezing,
    userPasswordChanging,
    isLogoutLoading,
  ]);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (userSettings) {
      console.log("User Settings are: ", userSettings);
      setToggleButtonChecked(userSettings?.allow_notification_email);
    }
  }, [userSettings]);

  useEffect(() => {
    if (view == 'plans') {
      setIsUpgradePlanPopupOpen(true);
    }
    else if (view === 'social_accounts') {
      setToggleState('social_accounts')
    }
  }, []);


  const showDiscountAppliedDialog = () => {
    setShowStayDiscountPopup(false);
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="flex flex-col items-center justify-center py-[40px]">
              <SvgCelebrationIcon viewBox="0 0 72 72" />
              <p className="mt-[10px] text-H6 text-black">
                {t("25-discount-applied-successfully")}
              </p>
              <p className="mt-[10px] text-BodyMedium text-gray text-center">
                {t(
                  "your-25-discount-is-now-active-starting-from-yout-next-billing-cycle"
                )}
                &nbsp;
                {t("enjoy-your-continued-journey-with-us")}
              </p>
              <div className="mt-[20px] h-[40px] w-full flex flex-col md:flex-row items-center justify-center">
                <PrimaryButton
                  className="h-full w-full md:w-[80px]"
                  onClick={() => {
                    dispatch(toggleDialog(false));
                  }}
                  title="Great!"
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };


  const showAccountDeletionConfirmationDialog = () => {
    setShowStayDiscountPopup(false);
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="flex flex-col items-center justify-center py-[40px]">
              <SvgThumbsUpTritoneIcon viewBox="0 0 72 72" />
              <p className="mt-[10px] text-H6 text-black">
                {t("account-deleted-successfully")}
              </p>
              <p className="mt-[10px] text-BodyMedium text-gray text-center">
                {t("your-account-has-been-permanently-deleted-along-with-all-associated-data")}
                &nbsp;
                {t("thank-you-for-using-evercopy")}
              </p>
              <div className="mt-[20px] h-[40px] w-full flex flex-col md:flex-row items-center justify-center">
                <PrimaryButton
                  className="h-full w-full md:w-[150px]"
                  onClick={() => {
                    cleanUp();
                    dispatch(toggleDialog(false));

                    dispatch(setActivePage(""));
                    logoutUser();
                    navigate('/login');
                  }}
                  title={t("go-back-to-homepage")}
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };


  const showAccountFrozenDialog = () => {
    setShowStayDiscountPopup(false);
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="flex flex-col items-center justify-center py-[40px]">
              <SvgThumbsUpTritoneIcon viewBox="0 0 72 72" />
              <p className="mt-[10px] text-H6 text-black">
                {t("your-account-is-frozen")}
              </p>
              <p className="mt-[10px] text-BodyMedium text-gray text-center">
                {t("your-data-remains-secure-for-6-months")}
                &nbsp;
                {t("unfreeze-any-moment-to-continue-your-journey-with-us")}
              </p>
              <div className="mt-[20px] h-[40px] w-full flex flex-col md:flex-row items-center justify-center">
                <PrimaryButton
                  className="h-full w-full md:w-[80px]"
                  onClick={() => {
                    dispatch(toggleDialog(false));
                    cleanUp();
                    dispatch(toggleDialog(false));

                    dispatch(setActivePage(""));
                    logoutUser();
                    navigate("/login");
                  }}
                  title={t("great")}
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };


  const showPlanCancellationConfirmedDialog = () => {
    setShowStayDiscountPopup(false);
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="flex flex-col items-center justify-center py-[40px]">
              <SvgThumbsUpTritoneIcon viewBox="0 0 72 72" />
              <p className="mt-[10px] text-H6 text-black">
                {t("plan-cancellation-confirmed")}
              </p>
              <p className="mt-[10px] text-BodyMedium text-gray text-center">
                {t("your-subscription-is-now-cancelled")}
                &nbsp;
                {t("access-continues-until-the-end-of-your-billing-cycle")}
              </p>
              <div className="mt-[20px] h-[40px] w-full flex flex-col md:flex-row items-center justify-center">
                <PrimaryButton
                  className="h-full w-full md:w-[80px]"
                  onClick={() => {
                    dispatch(toggleDialog(false));
                    getUser()
                  }}
                  title={t("close")}
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };

  const showDiscountAlreadyApplied = () => {
    setShowStayDiscountPopup(false);
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="flex flex-col items-center justify-center py-[40px]">
              <SvgThumbsUpTritoneIcon viewBox="0 0 72 72" />
              <p className="mt-[10px] text-H6 text-black">
                {t("discount-already-applied")}
              </p>
              <p className="mt-[10px] text-BodyMedium text-gray text-center">
                {t("you-have-already-used-a-discount-and-cannot-apply-it-again")}
              </p>
              <div className="mt-[20px] h-[40px] w-full flex flex-col md:flex-row items-center justify-center">
                <PrimaryButton
                  className="h-full w-full md:w-[80px]"
                  onClick={() => {
                    dispatch(toggleDialog(false));
                  }}
                  title={t("close")}
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };

  const showPasswordChangeConfirmedDialog = () => {
    setShowStayDiscountPopup(false);
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="flex flex-col items-center justify-center py-[40px]">
              <SvgThumbsUpTritoneIcon viewBox="0 0 72 72" />
              <p className="mt-[10px] text-H6 text-black">
                {t("password-change-successful")}
              </p>
              <p className="mt-[10px] text-BodyMedium text-gray text-center">
                {t("your-password-has-been-successfully-changed")}
              </p>
              <div className="mt-[20px] h-[40px] w-full flex flex-col md:flex-row items-center justify-center">
                <PrimaryButton
                  className="h-full w-full md:w-[80px]"
                  onClick={() => {
                    dispatch(toggleDialog(false));
                  }}
                  title={t("close")}
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };

  const showAccountReactivationConfirmedDialog = () => {
    setShowStayDiscountPopup(false);
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="flex flex-col items-center justify-center py-[40px]">
              <SvgCelebrationIcon viewBox="0 0 72 72" />
              <p className="mt-[10px] text-H6 text-black">
                {t("your-account-is-reactivated")}
              </p>
              <p className="mt-[10px] text-BodyMedium text-gray text-center">
                {t("your-account-is-back-in-action")}
                &nbsp;
                {t("all-features-and-data-are-now-accessible")}
                &nbsp;
                {t("continue-where-you-left-off")}
              </p>
              <div className="mt-[20px] h-[40px] w-full flex flex-col md:flex-row items-center justify-center">
                <PrimaryButton
                  className="h-full w-full md:w-[80px]"
                  onClick={() => {
                    dispatch(toggleDialog(false));
                  }}
                  title={t("great")}
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };


  const isFormValid = () => {

    setTimeout(() => {
      setWarningMessage("");
    }, 3000);
    if (oldPasswordField?.trim() === "" ||
      newPasswordField?.trim() === "" ||
      newPasswordRepeatField?.trim() === "") {
      setWarningMessage(t('please-enter-all-informations') ?? "")
      return false
    }

    if (newPasswordField?.trim() !== newPasswordRepeatField?.trim()) {
      setWarningMessage(t('passwords-dont-match') ?? "")
      return false
    }

    return true
  }

  const showDeleteAccountDialog = () => {
    setShowStayDiscountPopup(false);
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="fixed lg:top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center max-lg:justify-end max-lg:mb-1 justify-center h-screen" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
              <div className="w-full mx-2 md:mx-0 md:w-2/5 flex flex-col justify-center bg-white p-5 rounded-[10px]">
                <>
                  <div className="h-10 items-center flex justify-between">
                    <text className="text-black text-H6 mb-3">
                      {t("we-are-sorry-that-youre-leaving")}
                    </text>
                  </div>
                  <hr className="bg-border -ml-5 -mr-5" />
                  <text className="text-black text-BodyMedium my-5">
                    {t("what-is-the-reason-that-you-want-to-delete-your-account")}
                  </text>
                  <RadioSelector
                    ItemSelected={(item) => {
                      if (item) {
                        setReasonToDelete(item);
                      }
                    }}
                    Items={deleteAccountReasonsItems}
                  />

                  <TextView
                    initialValue={
                      ""
                    }
                    className="w-full h-[120px]"
                    placeholder={
                      t("anything-else-you-would-like-to-tell-us") ?? ""
                    }
                    title={t("anything-else-you-would-like-to-tell-us") ?? ""}
                    innerClassName="rounded-[4px]"
                    onChange={(e) => { setDeleteExplanation(e) }}
                    onBlur={() => { }}
                  />
                  <text className="text-black text-BodyMedium mb-5">
                    {t(
                      "deleting-your-account-will-permanently-erase-all-your-data-campaigns-and-settings"
                    )}
                    &nbsp;
                    {t("this-action-is-irreversible")}&nbsp;
                    {t(
                      "as-an-alternative-you-can-freeze-your-account-to-preserve-your-data"
                    )}
                  </text>
                  <hr className="bg-border -ml-5 -mr-5" />
                  <div className="mt-3 flex-col lg:flex lg:flex-row lg:justify-end">
                    <PrimaryButton
                      noFill
                      className="h-12 w-full lg:w-[160px]"
                      title={t("delete-permanently")}
                      onClick={() => {
                        dispatch(toggleLoading(true));
                        const selectedReason =
                          reasonToDelete.id as DeleteUserRequest["reason"];
                        deleteUser({
                          deleteUserRequest: {
                            reason: selectedReason,
                            explanation: deleteExplanation,
                          },
                        }).then(() => {
                          dispatch(toggleLoading(false));
                          showAccountDeletionConfirmationDialog();
                        });
                      }}
                    />
                    <PrimaryButton
                      noFill
                      className="h-12 w-full lg:ml-1 lg:w-[100px]"
                      title={t("freeze")}
                      onClick={() => {
                        dispatch(toggleLoading(true));
                        const selectedReason = reasonToDelete.id as FreezeUserRequest['reason'];
                        freezeUser({ freezeUserRequest: { reason: selectedReason, explanation: deleteExplanation } }).then(() => {
                          dispatch(toggleLoading(false));
                          showAccountFrozenDialog();
                        }
                        );
                      }}
                    />
                    <PrimaryButton
                      className="h-12 w-full lg:ml-1 lg:w-[100px]"
                      title={t("cancel")}
                      onClick={() => { dispatch(toggleDialog(false)) }}
                    />
                  </div>
                </>
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };


  const cleanUp = () => {
    dispatch(setLastSelectedBrandId(undefined));
    dispatch(setNewContent(undefined));
  };

  return (
    <>
      <StayDiscountPopup
        show={showStayDiscountPopup}
        onClickCancelPlan={() =>
          cancelSubscription().then(() => showPlanCancellationConfirmedDialog())
        }
        onClickApplyDiscount={() => showDiscountAppliedDialog()}
        onClose={() => setShowStayDiscountPopup(false)}
        onDiscountAlreadyApplied={() => {
          setShowStayDiscountPopup(false);
          showDiscountAlreadyApplied();
        }}
        isDiscountApplied={userSettings?.subscription?.is_discounted ?? false}
        currentPlan={userSettings?.subscription}
      />
      {showCreditCardDialog && (
        <EditCreditCardPopupOpener
          onClose={() => {
            setShowCreditCardDialog(false);
            dispatch(toggleDialog(false));
          }}
          onCardSetupCompleted={() => {
            setShowCreditCardDialog(false);
            dispatch(toggleDialog(false));
            setShowCreditCardUpdateSuccessPopup(true);
          }}
        />
      )}
      {showPlanUpdateSuccessPopup && (
        <ConfirmationPopupOpener
          heading={"Your Plan Has Been Updated Successfully!"}
          subHeading={
            effectiveAt == "IMMEDIATELY"
              ? "Your plan has been successfully updated. Adjusted features are now in effect."
              : "The current plan continues until the end of your billing cycle. Your new plan will start in the next billing cycle."
          }
          buttonText={"Continue"}
          onClose={() => {
            setShowPlanUpdateSuccessPopup(false);
            dispatch(toggleDialog(false));
            getUser();
          }}
        />
      )}
      {showAddressUpdateSuccessPopup && (
        <ConfirmationPopupOpener
          heading={"Billing Address Saved!"}
          subHeading={"Your billing address saved."}
          buttonText={"Close"}
          onClose={() => {
            setShowAddressUpdateSuccessPopup(false);
            dispatch(toggleDialog(false));
            getUser();
          }}
          icon={<ThumbsUp />}
        />
      )}
      {showCreditCardUpdateSuccessPopup && (
        <ConfirmationPopupOpener
          heading={"Payment Details Saved!"}
          subHeading={"Your credit card details saved successfully."}
          buttonText={"Close"}
          onClose={() => {
            setShowAddressUpdateSuccessPopup(false);
            dispatch(toggleDialog(false));
            getUser();
          }}
          icon={<ThumbsUp />}
        />
      )}
      {isUpgradePlanPopupOpen && (
        <PlanEditPopupOpener
          onClosed={() => {
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
          }}
          currentPlanId={userSettings?.subscription?.plan_id}
          paymentMethod={userSettings?.payment_method}
          onPlanUpdateSucceeded={(effectiveAt?: string) => {
            setEffectiveAt(effectiveAt ?? "");
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
            setShowPlanUpdateSuccessPopup(true);
          }}
          onUpdatePaymentMethod={() => {
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
          }}
        />
      )}
      <div className="relative min-w-full h-[56px] text-xl flex flex-row bg-white lg:-ml-[8px] -mr-[12px] border-1 border-border items-center justify-between">
        <text className="text-H6 text-black px-5 ">{t("settings")}</text>
      </div>
      <div className="w-full h-[calc(100vh-140px)] md:h-[calc(100vh-60px)] pl-40 pr-40 mt-10">
        <div className="w-full h-full pr-3 pl-3">
          <div className=" h-full mt-1 lg-mt-5 lg:h-[calc(100vh-150px)] flex flex-col ">
            <div className="w-full min-h-[60px] border-1 border-border flex flex-row items-center justify-start bg-white rounded-xl">

              {/* Account  */}
              <div
                className="flex-col h-full relative flex items-center pt-2"
                onClick={() => {
                  if (toggleState !== "account") {
                    setToggleState("account");
                  }
                }}
              >
                <text
                  className={`h-full ml-3 mt-3 text-black text-BodySmall font-semibold font-['Inter Tight'] leading-tight cursor-pointer ${toggleState === "account" ? "text-black" : "text-gray"
                    }`}
                >
                  {t("account")}
                </text>
                {toggleState === "account" && (
                  <div
                    className="w-full h-[2px] bg-red ml-2"
                    style={{
                      position: "absolute",
                      bottom: "0",
                    }}
                  ></div>
                )}
              </div>

              {/* Socoial Accounts */}
              <div
                className="flex-col h-full relative flex items-center pt-2"
                onClick={() => {
                  if (toggleState !== "social_accounts") {
                    setToggleState("social_accounts");
                  }
                }}
              >
                <text
                  className={`h-full ml-3 mt-3 text-black text-BodySmall font-semibold font-['Inter Tight'] leading-tight cursor-pointer ${toggleState === "social_accounts" ? "text-black" : "text-gray"
                    }`}
                >
                  {t("social-accounts")}
                </text>
                {toggleState === "social_accounts" && (
                  <div
                    className="w-full h-[2px] bg-red ml-2"
                    style={{
                      position: "absolute",
                      bottom: "0",
                    }}
                  ></div>
                )}
              </div>

              {/* Billing & Payment */}
              <div
                className="flex-col h-full relative flex items-center pt-2"
                onClick={() => {
                  if (toggleState !== "payment") {
                    setToggleState("payment");
                  }
                }}
              >
                <text
                  className={`h-full ml-3 mt-3 text-black text-BodySmall font-semibold font-['Inter Tight'] leading-tight cursor-pointer ${toggleState === "payment" ? "text-black" : "text-gray"
                    }`}
                >
                  {t("payment-billing")}
                </text>
                {toggleState === "payment" && (
                  <div
                    className="w-full h-[2px] bg-red ml-2"
                    style={{
                      position: "absolute",
                      bottom: "0",
                    }}
                  ></div>
                )}
              </div>




            </div>
            {toggleState === "account" && (
              <>
                <div className="flex flex-col w-full scrollbar-hide flex-col overflow-scroll">
                  <div className="flex flex-row w-full ">
                    <div className="flex flex-col w-2/3 h-full overflow-scroll scrollbar-hide">
                      <ContentContainer
                        className={`mt-[20px]  w-full`}
                        hoverable={false}
                        children={
                          <>
                            <div className="h-[56px] w-full flex items-center border-b-1 border-border justify-between">
                              <text className="text-H6 text-black ml-[15px] ">
                                {t("profile-information")}
                              </text>
                            </div>
                            <div className="w-full flex-col flex justify-center ml-[15px] pr-[30px]">
                              <InputText
                                title={t("mail-address") ?? ""}
                                className="mt-4"
                                state={InputTextState.disabled}
                                placeholder={userSettings?.email_address ?? ""}
                                onChange={(e) => { }}
                                sideBySide={true}
                                innerClassName="w-1/2 rounded-[4px]"
                              />
                              <div className="flex flex-row justify-between mt-3">
                                <text className="text-H8 text-black mb-2">
                                  {t("allow-notification-emails")}
                                </text>
                                <div className="w-1/2 flex items-center">
                                  <ToggleButtonSmall
                                    isChecked={toggleButtonChecked}
                                    handleCheckBoxChange={() => {
                                      setToggleButtonChecked(
                                        !toggleButtonChecked
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <hr className="bg-border mt-2 -ml-5 -mr-4" />
                              <div className="flex justify-end">
                                <PrimaryButton
                                  className="mt-2 mb-2 "
                                  title={t("save")}
                                  onClick={() => {
                                    updateUserSettings({
                                      userSettingsUpdateRequest: {
                                        allow_notification_email:
                                          toggleButtonChecked,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        }
                      />

                      {(userSettings?.auth_provider ?? "").indexOf("local") !==
                        -1 && (
                          <ContentContainer
                            className={`mt-[20px] w-full`}
                            hoverable={false}
                            children={
                              <>
                                <div className="h-[56px] w-full flex items-center border-b-1 border-border justify-between">
                                  <text className="text-H6 text-black ml-[15px] ">
                                    {t("change-password")}
                                  </text>
                                </div>
                                <div className="w-full flex-items-center ml-[15px] pr-[30px]">
                                  <WarningMessage
                                    title={warningMessage}
                                    show={
                                      warningMessage != "" &&
                                      !userPasswordSuccessfullyChanged
                                    }
                                    className="w-full mt-5"
                                    onClose={() => {
                                      setWarningMessage("");
                                    }}
                                  />
                                  <InputText
                                    icon={SvgEyeCrossedIcon}
                                    alternateIcon={SvgEyeIcon}
                                    iconPosition="end"
                                    title={t("old-password") ?? ""}
                                    className="mt-4"
                                    placeholder={t("old-password") ?? ""}
                                    onChange={(e) => {
                                      if (e) {
                                        setOldPasswordField(e);
                                      }
                                    }}
                                    sideBySide={true}
                                    innerClassName="w-1/2 rounded-[4px]"
                                  />
                                  <InputText
                                    icon={SvgEyeCrossedIcon}
                                    alternateIcon={SvgEyeIcon}
                                    iconPosition="end"
                                    title={t("new-password") ?? ""}
                                    placeholder={t("new-password") ?? ""}
                                    className="mt-4"
                                    onChange={(e) => {
                                      if (e) {
                                        setNewPasswordField(e);
                                      }
                                    }}
                                    sideBySide={true}
                                    innerClassName="w-1/2 rounded-[4px]"
                                  />
                                  <InputText
                                    icon={SvgEyeCrossedIcon}
                                    alternateIcon={SvgEyeIcon}
                                    iconPosition="end"
                                    title={t("confirm-new-password") ?? ""}
                                    placeholder={t("new-password-again") ?? ""}
                                    className="mt-4"
                                    onChange={(e) => {
                                      if (e) {
                                        setNewPasswordRepeatField(e);
                                      }
                                    }}
                                    sideBySide={true}
                                    innerClassName="w-1/2 rounded-[4px]"
                                  />
                                  <hr className="bg-border mt-2 -ml-5 -mr-4" />
                                  <div className="flex justify-end">
                                    <PrimaryButton
                                      className="mt-2 mb-2 w-[140px] h-[40px] "
                                      title={t("change-password")}
                                      onClick={() => {
                                        if (!isFormValid()) {
                                          return;
                                        }
                                        changePassword({
                                          updateOwnPasswordRequest: {
                                            old_password: oldPasswordField,
                                            new_password: newPasswordField,
                                          },
                                        }).then((p) => {
                                          if ("data" in p) {
                                            const result = p as {
                                              data: ApiResponse;
                                            };
                                            if (
                                              result.data.message ===
                                              "Own password successfully updated."
                                            ) {
                                              showPasswordChangeConfirmedDialog();
                                            } else {
                                              setWarningMessage(
                                                "Password change unsuccessful, check your passwords."
                                              );
                                            }
                                          } else {
                                            setWarningMessage(
                                              "Password change unsuccessful, check your passwords."
                                            );
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            }
                          />
                        )}
                      <div className="w-full mt-4 h-[60px] border-1 border-border flex flex-row items-center justify-start justify-between bg-white rounded-xl">
                        <text className="text-H7 text-red ml-[15px] ">
                          {t("delete-account")}
                        </text>
                        <PrimaryButton
                          className="mt-2 mr-2 mb-2 w-[140px] h-[40px] "
                          noFill
                          title={t("delete-account")}
                          onClick={() => {
                            showDeleteAccountDialog();
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-1/3 pl-3">
                      <ContentContainer
                        className={`mt-[20px] w-full pr-5 pl-5`}
                        hoverable={false}
                        children={
                          <>
                            <div className="flex flex-col w-full">
                              <div className="h-[56px] w-full flex items-center border-b-1 border-border">
                                <text className="text-H6 text-black  ">
                                  Current Plan:
                                </text>
                                <text className="text-H6 text-red ml-[5px] ">
                                  {userSettings?.subscription?.plan_name ?? ""}
                                </text>
                              </div>
                              <div className="w-full flex flex-row justify-between mt-4">
                                <text className="text-H8 text-black ">
                                  Credits
                                </text>
                                <text className="text-H8 text-black ml-[5px] ">
                                  {/*
                                  {(userSettings?.subscription?.plan_name ??
                                    "") === "Agencies"
                                    ? "Unlimited"
                                    : `${(userSettings?.subscription
                                      ?.credits_available ?? 10000) -
                                    (userSettings?.subscription
                                      ?.credits_used ?? 0)
                                    } / ${userSettings?.subscription
                                      ?.credits_available ?? 10000
                                    }`}
                                  */}
                                  {`${(userSettings?.subscription
                                    ?.credits_available ?? 10000) -
                                    (userSettings?.subscription?.credits_used ??
                                      0)
                                    } / ${userSettings?.subscription
                                      ?.credits_available ?? 10000
                                    }`}
                                </text>
                              </div>

                              <div className="flex w-full relative h-[8px] rounded-[100px] items-center justify-center mt-2">
                                <div className="w-full bg-border opacity-50  h-[8px]  top-0 left-0 rounded-[100px]" />
                                <div
                                  className={`bg-red absolute h-[8px] top-0 left-0 rounded-[100px]`}
                                  style={{
                                    width:
                                      (userSettings?.subscription?.plan_name ??
                                        "") === "Agencies"
                                        ? "100%"
                                        : `${Math.ceil(
                                          (100 *
                                            ((userSettings?.subscription
                                              ?.credits_available ?? 10000) -
                                              (userSettings?.subscription
                                                ?.credits_used ?? 0))) /
                                          (userSettings?.subscription
                                            ?.credits_available ?? 10000)
                                        )}%`,
                                  }}
                                />
                              </div>
                              {!userSettings?.subscription?.cancels_at && (
                                <text className="text-BodySmall text-gray mt-1">
                                  Renews at:{" "}
                                  {`${Utils.formatTimestampToDateString(
                                    userSettings?.subscription
                                      ?.current_period_end_date ?? 0
                                  )}`}
                                </text>
                              )}
                              {userSettings?.subscription?.cancels_at && (
                                <div className="self-stretch text-rose-500 text-[13px] font-normal font-['Inter Tight'] leading-[18.20px]">{`Your plan ends at ${Utils.formatTimestampToDateString(
                                  userSettings.subscription.cancels_at
                                )}.`}</div>
                              )}

                              <div className="w-full flex items-center justify-center mb-4">
                                <PrimaryButton
                                  onClick={() => {
                                    setIsUpgradePlanPopupOpen(true);
                                  }}
                                  icon={
                                    !userSettings?.subscription?.cancels_at ? (
                                      <SvgUploadIconNew
                                        fill="#ffffff"
                                        className="mr-2"
                                      />
                                    ) : undefined
                                  }
                                  title={
                                    userSettings?.subscription?.cancels_at
                                      ? t("choose-plan")
                                      : t("update-plan")
                                  }
                                  className="h-[44px] w-full mt-5"
                                />
                              </div>
                              {!userSettings?.subscription?.cancels_at && (
                                <Button
                                  type="text"
                                  shape="round"
                                  size="small"
                                  onClick={() => {
                                    setShowStayDiscountPopup(true);
                                  }}
                                  className="self-center text-center mb-4 text-gray underline "
                                >
                                  {t("cancel-plan")}
                                </Button>
                              )}
                            </div>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}


            {toggleState === "social_accounts" && (
              <>
                <div className="flex flex-col w-full scrollbar-hide overflow-scroll">
                  <SocialAccountListing
                    key={listingKey}
                    onAddNewClicked={() => {
                      setShowAddSocialAccountsDialog(true);
                      dispatch(toggleDialog(true));
                    }}></SocialAccountListing>
                </div>
              </>
            )}


            {toggleState === "payment" && (
              <div className="flex w-full scrollbar-hide flex-col overflow-scroll">
                <ContentContainer
                  className={`mt-[20px]  w-full`}
                  hoverable={false}
                  children={
                    <>
                      <div className="h-[56px] w-full flex items-center border-b-1 border-border justify-between">
                        <text className="text-H6 text-black ml-[15px] ">
                          {t("payment")}
                        </text>
                      </div>
                      <div className="w-full flex-col flex justify-center ml-[15px] pr-[30px]">
                        <InputText
                          title={t("credit-card") ?? ""}
                          color="gray"
                          className="mt-4"
                          onChange={(e) => { }}
                          value={`${TextUtils.capitalizeFirstLetter(
                            userSettings?.payment_method?.brand ?? ""
                          )} **** ${userSettings?.payment_method?.last4
                            }, Expiration ${userSettings?.payment_method?.exp_year
                            }`}
                          sideBySide={true}
                          state={InputTextState.disabled}
                          sideBySideText={t("edit-credit-card") || ""}
                          onClickSideText={() => {
                            setShowCreditCardDialog(true);
                          }}
                          innerClassName="w-3/4 rounded-[4px]"
                        />
                        <InputText
                          title={t("billing-address") ?? ""}
                          className="my-4"
                          color="gray"
                          onChange={(e) => { }}
                          value={`${userSettings?.billing_address?.line1}, ${userSettings?.billing_address?.state}, ${userSettings?.billing_address?.city}, ${userSettings?.billing_address?.postal_code}, ${userSettings?.billing_address?.country}`
                            .substring(0, 72)
                            .concat(`...`)}
                          onClickSideText={() => {
                            setShowAddressEditDialog(true);
                            dispatch(toggleDialog(true));
                          }}
                          sideBySide={true}
                          state={InputTextState.disabled}
                          sideBySideText={t("edit-billing-address") || ""}
                          innerClassName="w-3/4 rounded-[4px]"
                        />
                      </div>
                    </>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div >
      {showAddressEditDialog && (
        <EditAddressPopupOpener
          onClose={function (): void {
            dispatch(toggleDialog(false));
            setShowAddressEditDialog(false);
          }}
          userAddress={userSettings?.billing_address}
          onAddressUpdated={() => {
            setShowAddressEditDialog(false);
            setShowAddressUpdateSuccessPopup(true);
          }}
        ></EditAddressPopupOpener>
      )
      }

      {
        showAddSocialAccountsDialog && (
          <AddSocialAccountsPopupOpener
            onClose={function (): void {
              dispatch(toggleDialog(false));
              setShowAddSocialAccountsDialog(false);
              setListingKey(uuidv4())
            }}
          ></AddSocialAccountsPopupOpener>
        )
      }
    </>
  );
};

export default AccountSettingsPage;
