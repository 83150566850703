import { coreApi as api } from "./Core/BaseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    upload: build.mutation<UploadApiResponse, UploadApiArg>({
      query: (queryArg) => ({
        url: `/file/upload`,
        method: "POST",
        body: queryArg.body,

      }),
    }),
    download: build.query<DownloadApiResponse, DownloadApiArg>({
      query: (queryArg) => ({
        url: `/file/download/${queryArg.id}`,
        responseHandler: r => {
          return r.blob()
        },
      }),
      transformResponse: (response, meta: any) => {
        const fileName = meta?.response.headers.get("Content-Disposition").split("filename=")[1]
        return {file: response, name: fileName};
      }
    }),
    summarize: build.query<SummarizeApiResponse, SummarizeApiArg>({
      query:(queryArg) => ({
        url: `/file/summarize/${queryArg.id}`
      })
    }),
      downloadImage: build.query<any, DownloadApiArg>({
          query: ({ id }) => ({
              url: `file/download/image/${id}`,
              responseHandler: r => {
                  return r.blob()
              },
          }),
      }),
    deleteFileDeleteById: build.mutation<
      DeleteFileDeleteByIdApiResponse,
      DeleteFileDeleteByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/file/delete/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UploadApiResponse = /** status 200 OK */ UploadResponse;
export type UploadApiArg = {
  body: FormData;
};
export type DownloadApiResponse = any;
export type DownloadApiArg = {
  id: string;
};
export type SummarizeApiResponse = any;
export type SummarizeApiArg = {
  id: string;
}
export type DeleteFileDeleteByIdApiResponse = unknown;
export type DeleteFileDeleteByIdApiArg = {
  id: string;
};
export type UploadResponse = {
  ids?: string;
};
export const {
  useUploadMutation,
  useDownloadQuery,
  useLazyDownloadQuery,
  useDeleteFileDeleteByIdMutation,
  useSummarizeQuery,
  useLazySummarizeQuery,
  useLazyDownloadImageQuery,
} = injectedRtkApi;
