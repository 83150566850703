import { useDispatch } from "react-redux";
import SvgCreativeAdWand from "../../assets/IconComponents/CreativeAdWand";
import ContentImage from "../../components/ContentImage";
import { InspirationResponse } from "../../services/InspirationApi";
import { setGuidelinesState, setNewContent, setSelectedForYouInspiration } from "../../store/contentSlice";
import { ContentType } from "../../constants/ContentType";
import ContentWizard from "./ContentWizard";
import { ContentWizardView } from "../../models/ContentWizardView";
import { ContentSize } from "../../constants/ContentSize";
import moment from "moment";
import { useAppSelector } from "../../helpers/hooks";
import { INewContent } from "../../models/NewContent";
import { toggleContentWizard } from "../../store/appSlice";
import ContentContainer from "../../components/ContentContainer";
import { Utils } from "../../helpers/Utils";
import { useState } from "react";
import { Mediums } from "../../constants/Mediums";
import { Button } from "antd";
import SvgMagic from "../../assets/IconComponents/Magic";

interface IInspirationsForQuickstartGridProps {
  inspirations?: InspirationResponse[];
  onRefresh: () => void;
  onClick: (revisionId: string) => void;
  isCreative?: boolean;
}
function InspirationsForQuickstartGrid(
  props: IInspirationsForQuickstartGridProps
) {
  const dispatch = useDispatch();
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const newContent = useAppSelector((k) => k.content.newContent);
  const inspirationsToShow = props.isCreative
    ? props.inspirations?.slice(0, 9)
    : props.inspirations?.slice(0, 10);


  return (
    <div className="flex flex-col w-[1050px] items-center">
      <div className="flex flex-row w-full grid grid-cols-5 gap-2">
        {props.isCreative && (

          <div className="flex-col h-full ">
            <ContentContainer
              hoverable
              children={
                <div
                  className="flex  w-full h-[210px] border-1 border-slightRed items-center justify-center rounded-[8px] cursor-pointer"
                  onClick={() => {
                    dispatch(
                      setNewContent({
                        ...newContent,
                        medium: Mediums.filter(m => m.contentType === ContentType.image && m.isCustom === true).at(0),
                        contentType: ContentType.image,
                        wizardView: ContentWizardView.contentDetails,
                        loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                        brand: { id: lastSelectedBrandId },
                        isCreative: true,
                      } as INewContent)
                    );
                    dispatch(toggleContentWizard());
                  }}
                >
                  <div className="flex flex-col w-full h-full justify-center items-center">
                    <SvgCreativeAdWand fill={"#FF5555"} />
                    <span className="text-BodyMedium font-medium text-red  truncate text-ellipsis overflow-hidden mt-5">
                      Upload your own inspiration
                    </span>
                  </div>
                </div>
              }
            />

            <div
              className={`flex flex-col items-center justify-center w-full mt-2`}>
              <span className="text-H8 text-black">
                {""}
              </span>
            </div>
          </div>
        )}
        {inspirationsToShow?.map((inspo, index) => {
          return (
            <div key={index} className="flex-col ">
              <ContentContainer
                hoverable
                overlayActiveOnHover={true}
                onHoverElement={
                  <>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setGuidelinesState(0));
                        if (inspo.type === "creative") {
                          dispatch(
                            setNewContent({
                              ...newContent,
                              medium: Mediums.filter(
                                (m) =>
                                  m.contentType === ContentType.image &&
                                  m.isCustom === true
                              ).at(0),
                              adId: inspo.adId,
                              contentType: ContentType.image,
                              wizardView: ContentWizardView.contentDetails,
                              loopName: `Loop_${moment().format(
                                "DD_MM_yyyy_hh_mm"
                              )}`,
                              brand: { id: lastSelectedBrandId },
                              isCreative: true,
                            } as INewContent)
                          );
                          dispatch(toggleContentWizard());
                          dispatch(setSelectedForYouInspiration(inspo));
                        } else {
                          if (inspo.adId) {
                            dispatch(
                              setNewContent({
                                ...newContent,
                                adId: inspo.adId,
                              })
                            );
                          }
                          props.onClick(inspo.revisionId ?? "");
                        }
                      }}
                      style={{ left: "50%", top: "45%", transform: "translateX(-50%)" }} className="flex flex-row  p-1 py-1.5 items-center text-H5 font-normal text-black justify-center  absolute bottom-10 bg-white w-2/3 h-9 border rounded">
                      <SvgMagic width={14} height={14} className="mr-2" fill="currentColor" />
                      Create Similar
                    </button>
                  </>
                }
                children={
                  <div className="flex-col p-0.5">
                    {inspo.type === "text" && (
                      <div
                        className={`w-full relative h-[210px] rounded-[8px] border-border border-1 cursor-pointer`}
                      >
                        <p
                          style={{
                            whiteSpace: "pre-wrap",
                            backgroundColor: "rgba(246, 246, 246)",
                          }}
                          className={`w-full h-[210px] p-3 overflow-scroll scrollbar-hide text-black text-BodyMedium text-[13px] whitespace-pre-wrap pb-2 rounded-[8px]`}
                        >
                          {inspo.content ?? ""}
                        </p>
                      </div>
                    )}
                    {(inspo.type === "image" || inspo.type === "creative") && (
                      <div
                        className={`w-full relative h-[210px] rounded-[8px] cursor-pointer`}
                      >
                        <ContentImage
                          id={inspo?.fileId ?? ""}
                          className="py-1 h-[210px] bg-bg w-full rounded-[8px] object-contain"
                        />
                      </div>
                    )}
                  </div>
                }
              />
              <div
                className={`flex flex-col ml-1 justify-start w-full mt-2 mb-2`}
              >
                {inspo.type === "creative" && (
                  <>
                    <span className="text-BodyMedium font-inter-tight font-medium text-black">
                      {inspo.subCategory}
                    </span>
                    <span className="text-BodySmall text-footnote"> {inspo.type} </span>
                  </>
                )}
                {inspo.type !== "creative" && (
                  <>
                    <span className="text-BodyMedium font-inter-tight font-medium text-black">
                      {Utils.formatPlatformName(inspo.platform ?? "") === ""
                        ? "Creative"
                        : Utils.formatPlatformName(inspo.platform ?? "")}
                    </span>
                    <span className="text-BodySmall text-footnote"> {inspo.type} </span>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <Button
        type="default"
        className="flex flex-col items-center  mt-2 justify-center "
        onClick={() => props.onRefresh()}
      >
        Load More
      </Button>

    </div>
  );
}

export default InspirationsForQuickstartGrid;
