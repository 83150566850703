import { FunctionComponent, useEffect, useState } from "react";
import SvgImageSliderCounterLogo from "../../../assets/IconComponents/ImageSliderCounterLogo";
import { InputNumber, Slider, Space } from "antd";
import { useAppSelector } from "../../../helpers/hooks";
interface SliderCounterProps {
  title?: string;
  className?: string;
  numberOfImagesChanged?: (num: number) => void;
  minCount?: number;
  maxCount?: number;
}

const SliderCounter: FunctionComponent<SliderCounterProps> = (
  props: SliderCounterProps
) => {
    const [currentCount, setCurrentCount] = useState<
      number 
    >(4);
    const newContent = useAppSelector((k) => k.content.newContent);

  useEffect(() => {
    if (newContent?.sliderCount) {
      setCurrentCount(newContent?.sliderCount);
    }
  }, [newContent]);

  return (
    <div
      className={`self-stretch justify-start items-start gap-3 flex flex-row ${props.className}`}
    >
      <div className="w-[240px] h-7 justify-start items-center gap-2.5 flex shrink-0">
        <SvgImageSliderCounterLogo />
        <div className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">
          Number of Images
        </div>
      </div>

      <div className="flex flex-row w-full">
        <div className="flex flex-col w-full justify-center items-end gap-2 mr-4">
          <div className="w-2/3">
            <Slider
              tooltip={{ formatter: null }}
              styles={{
                track: {
                  borderRadius: "5px",
                  height: "4px",
                  backgroundColor: "#ff0d00",
                  color: "#ff0d00",
                },
                rail: {
                  height: "4px",
                },
                handle: {
                  width: "14px", // Adjust width and height as needed
                  height: "14px",
                  borderRadius: "50%",
                  top: 3,
                  border: "2px solid #ff0d00", // 1px red border
                  backgroundColor: "#ffffff", // White background
                },
              }}
              defaultValue={4}
              min={props.minCount ?? 0}
              max={props.maxCount ?? 4}
              onChange={(value) => {
                if(value != 0) {
                  setCurrentCount(value);
                  if (props.numberOfImagesChanged) {
                    props.numberOfImagesChanged(value);
                  }
                }
                
              }}
              value={currentCount}
            />
          </div>
        </div>

        <div className="mt-2 w-[50px] h-[24px] justify-center items-center bg-white rounded-[3px] border border-stone-200">
          <div className="text-BodySmall pl-2 text-start text-black">
            {currentCount}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCounter;
