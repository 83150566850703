import {
    AndroidOutlined, AppleOutlined, BookFilled, FacebookFilled, InstagramFilled, LinkedinFilled,
    MailFilled, TwitterSquareFilled
} from "@ant-design/icons";
import {Button, Tabs, TabsProps} from "antd";
import {setNewContent} from "../../../../store/contentSlice";
import {ContentType} from "../../../../constants/ContentType";
import {ContentWizardView} from "../../../../models/ContentWizardView";
import {Mediums} from "../../../../constants/Mediums";
import moment from "moment/moment";
import {INewContent} from "../../../../models/NewContent";
import {useAppSelector} from "../../../../helpers/hooks";
import {useDispatch} from "react-redux";
import {AudienceResponse} from "../../../../services/AudiencetApi";
import ContentDetails from "../../../../pages/NewContentWizard/ContentDetails/ContentDetails";
import {useEffect, useState} from "react";
import {FileProps, useLazyFindById4Query} from "../../../../services/BrandApi";
import LoopDetails from "../../../../pages/LoopDetails/LoopDetails";
import SvgCreateBanner from "../../../../assets/IconComponents/CreateBanner";
import ContentBanner from "../../../../pages/ContentWizard/ContentBanner";
import SvgCreativeAdWand from "../../../../assets/IconComponents/CreativeAdWand";
import ContentCreativePage from "../../../../pages/ContentWizard/ContentCreativePage";
import CreatePresetContentCard from "../../../../pages/ContentWizard/CreatePresetContentCard";
import {ContentSize, SizeConstants} from "../../../../constants/ContentSize";
import {toggleContentWizard} from "../../../../store/appSlice";
import Carousel from "react-multi-carousel";
import ThemeInspirationSelector from "./ThemeInspirationSelector";
import LogoSelector from "../../../../pages/NewContentWizard/ContentDetails/LogoSelector";
import ProductSelector from "../../../../pages/NewContentWizard/ContentDetails/ProductSelector";
import MarketingImagePicker from "../../../MarketingPlanner/MarketingImagePicker";

const bannerList = [
    {contentSize: 'post', title: 'Post', sampleImagePath: 'content-instagram-post-sample.png', optionAt: 8},
    {contentSize: 'landscape', title: 'Landscape', sampleImagePath: 'content-banner-landscape-sample.png', optionAt: 9},
    {contentSize: 'story', title: 'Story', sampleImagePath: 'content-instagram-story-sample.png', optionAt: 10},
    {
        contentSize: 'mobilemedr',
        title: 'Mobile Medium Rectangle',
        sampleImagePath: 'content-mobile-medium-rectangle-sample.png',
        optionAt: 0
    },
    {
        contentSize: 'mobileleaderb',
        title: 'Mobile Leaderboard',
        sampleImagePath: 'content-mobile-leaderboard-sample.png',
        optionAt: 6
    },
]

const creativeList = [
    {
        contentSize: 'creative_post',
        title: 'Post',
        imageHeight: "1080",
        imageWidth: "1080",
        sampleImagePath: 'content-creative-ad-post-sample.png',
        optionAt: 0
    },
    {
        contentSize: 'creative_landscape',
        title: 'Landscape',
        imageHeight: "628",
        imageWidth: "1200",
        sampleImagePath: 'content-creative-ad-landscape-sample.png',
        optionAt: 0
    },
    {
        contentSize: 'creative_story',
        title: 'Story',
        imageHeight: "1920",
        imageWidth: "1080",
        sampleImagePath: 'content-creative-ad-story-sample.png',
        optionAt: 0
    },
]

const SelectImageContent = (props) => {

    const dispatch = useDispatch();
    const lastSelectedBrandId = useAppSelector((k) => k.persisted.lastSelectedBrandId);
    const newContent = useAppSelector((k) => k.content.newContent);

    const [editingAudience, setEditingAudience] = useState<AudienceResponse>()
     const [recentlyAddedAudience, setRecentlyAddedAudience] = useState<AudienceResponse[]>([])
    const [getBrand, {data: brand, isLoading: brandLoading}] = useLazyFindById4Query()
    const [contentCreationDataID, setContentCreationDataID] = useState('')
    const [createdAIImages, setCreatedAIImages] = useState<any[]>()
    const [activeTab, setActiveTab] = useState('1')
    const {
        step,
        setStep,
        setIsProductSelectorOpen,
        setIsLogoSelectionOpen,
        setIsInspirationThemeSelectionOpen,
        isInspirationThemeSelectionOpen,
        isProductSelectorOpen,
        isLogoSelectionOpen,
        backToSelection
    } = props
    const [selectedProductImages, setSelectedProductImages] = useState<FileProps[]>([]);
    const [productImages, setProductImages] = useState<FileProps[]>([]);
    const [selectedCompanyLogos, setSelectedCompanyLogos] = useState<FileProps[]>([]);
    const [companyLogos, setCompanyLogos] = useState<FileProps[]>([]);

    useEffect(() => {
        if (newContent) {
            getBrand({id: newContent?.brand?.id ?? ""})
        }
    }, [newContent]);

    useEffect(() => {
        if (brand?.logo_file_ids && companyLogos.length === 0) {
            setCompanyLogos(brand.logo_file_ids)
        }
    }, [brand])

    useEffect(() => {
        if (brand?.product_file_ids && productImages.length === 0) {
            setProductImages(brand.product_file_ids)
        }
    }, [brand])

    useEffect(() => {
        if (contentCreationDataID.length) {
            setStep(2)
        }
    }, [contentCreationDataID]);

    useEffect(() => {
        if (createdAIImages) {
            console.log(createdAIImages)
            props.setGeneratedAIImages(createdAIImages)
            //props.closeSideModal()
        }
    }, [createdAIImages]);

    const onTabChange = (key: string) => {
        setActiveTab(key);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Banner',
            icon: <SvgCreateBanner fill="#121315"/>,
        },
        {
            key: '2',
            label: 'Creative',
            icon: <SvgCreativeAdWand fill="#121315"/>,
        },
    ];

    {/* <span role="img" aria-label="apple" class="anticon anticon-apple"><SvgCreateBanner  fill="#000000" width="1em" height="1em"/></span> */
    }
    return (
        <div className={'w-full min-h-[74vh] mt-3 relative'}>

            <Tabs defaultActiveKey="1" items={items.map((item, i) => {
                return {
                    key: item.key,
                    label: item.label,
                    icon: <span role="img" aria-label="apple" className="anticon anticon-apple">{item.icon}</span>,
                };
            })}
                                 onChange={onTabChange}
                                 className={`${step === 0 ? '' : 'hidden'} border-1 border-border pt-0 mb-2 rounded-md px-3`}
                                 popupClassName="mb-0"
            />
            {activeTab === '1' && <>
                {step === 0 && <div className={'flex flex-wrap'}>
                    {bannerList.map(banner => (
                        <CreatePresetContentCard
                            isInSide
                            onClick={() => {
                                dispatch(
                                    setNewContent({
                                        ...newContent,
                                        contentType: ContentType.image,
                                        wizardView: ContentWizardView.contentDetails,
                                        contentSize: ContentSize[banner.contentSize],
                                        mediumOption: Mediums.find(
                                            (k) =>
                                                k.name === "Banner" && k.contentType === ContentType.image
                                        )?.options?.at(banner.optionAt),
                                        medium: Mediums.find(
                                            (k) =>
                                                k.isCustom === true && k.contentType === ContentType.image
                                        ),
                                        imageHeight: SizeConstants[ContentSize[banner.contentSize]].height + "",
                                        imageWidth: SizeConstants[ContentSize[banner.contentSize]].width + "",
                                        isCreative: false,
                                        loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                                        brand: {id: lastSelectedBrandId},
                                    } as INewContent)
                                );
                                setStep(1)
                            }}
                            title={banner.title}
                            sampleImagePath={banner.sampleImagePath}
                        />
                    ))}

                </div>}

                {step === 1 &&
                    <>
                        {(!isLogoSelectionOpen && !isProductSelectorOpen) && <ContentDetails
                            isInSide
                            setContentCreationDataID={setContentCreationDataID}
                            setIsLogoSelectionOpen={setIsLogoSelectionOpen}
                            setIsProductSelectorOpen={setIsProductSelectorOpen}
                            logoSelectionList={selectedCompanyLogos}
                            productSelectionList={selectedProductImages}
                            onAudienceEdit={(data: AudienceResponse) => {
                                setEditingAudience(data);
                                dispatch(
                                    setNewContent({
                                        ...newContent,
                                        wizardView: ContentWizardView.audience,
                                    } as INewContent)
                                );
                            }}
                            onAudienceCreate={() => {
                                setEditingAudience(undefined);
                                dispatch(
                                    setNewContent({
                                        ...newContent,
                                        wizardView: ContentWizardView.audience,
                                    } as INewContent)
                                );
                            }}
                            recentlyAddedAudience={recentlyAddedAudience}
                        />}
                        {isLogoSelectionOpen && <MarketingImagePicker
                            uploadButtonEnabled={true}
                            uploadedFiles={companyLogos}
                            isGrid
                            type={'inspiration'}
                            hideFileName = {true}
                            title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                            description="Upload .jpg or .png files. Max 25mb."
                            fileChanged={(f: any) => {
                                if (f) {
                                    const lastFile: FileProps = {
                                        id: f.uploadedId,
                                        name: f.name,
                                        uploaded: true,
                                    };
                                    setCompanyLogos((prevItems) => [
                                        lastFile,
                                        ...prevItems,
                                    ]);
                                    if (!selectedCompanyLogos.length) {
                                        setSelectedCompanyLogos(prev => {
                                            const temp = [lastFile, ...prev]
                                            /*if(props.setSelectedProductImages) {
                                                props.setSelectedProductImages(temp)
                                            }*/
                                            return temp
                                        })
                                    }
                                }
                            }}
                            selectionChanged={(pickedFile) => {
                                /* if (pickedFile?.id) {
                                     if (selectedCompanyLogos.indexOf(pickedFile.id) === -1 && selectedCompanyLogos.length <= 10) {
                                         setSelectedCompanyLogos(prev => [String(pickedFile.id), ...prev])
                                     } else {
                                         setSelectedCompanyLogos(prev => prev.filter(item => item !== String(pickedFile.id)))
                                     }
                                 }*/
                                setSelectedCompanyLogos(prev => [pickedFile])
                            }}
                            selectionEnabled={true}
                            selectedImages={selectedCompanyLogos.map(item => item.id ?? "")}
                            selectedFileId={undefined}
                        />}
                        {isProductSelectorOpen && <MarketingImagePicker
                            uploadButtonEnabled={true}
                            uploadedFiles={productImages}
                            hideFileName = {true}
                            isGrid
                            type={'inspiration'}
                            title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                            description="Upload .jpg or .png files. Max 25mb."
                            fileChanged={(f: any) => {
                                if (f) {
                                    const lastFile: FileProps = {
                                        id: f.uploadedId,
                                        name: f.name,
                                        uploaded: true,
                                    };
                                    setProductImages((prevItems) => [
                                        lastFile,
                                        ...prevItems,
                                    ]);
                                    if (!selectedProductImages.length) {
                                        setSelectedProductImages(prev => {
                                            const temp = [(lastFile), ...prev]
                                            /*if(props.setSelectedProductImages) {
                                                props.setSelectedProductImages(temp)
                                            }*/
                                            return temp
                                        })
                                    }
                                }
                            }}
                            selectionChanged={(pickedFile) => {
                                /*if (pickedFile?.id) {
                                    if (selectedProductImages.indexOf(pickedFile.id) === -1 && selectedProductImages.length <= 10) {
                                        setSelectedProductImages(prev => [String(pickedFile.id), ...prev])
                                    } else {
                                        setSelectedProductImages(prev => prev.filter(item => item !== String(pickedFile.id)))
                                    }
                                }*/
                                setSelectedProductImages(prev => [(pickedFile)])
                            }}
                            selectionEnabled={true}
                            selectedImages={selectedProductImages.map(item => item.id ?? "")}
                            selectedFileId={undefined}
                        />}
                        {(isProductSelectorOpen || isLogoSelectionOpen) && <>

                            <div className={'flex justify-end px-3 py-3 w-full absolute bottom-1 right-1'}>
                                <Button
                                    disabled={isProductSelectorOpen ? !selectedProductImages.length : !selectedCompanyLogos.length}
                                    type={'primary'} onClick={() => {
                                    if (isProductSelectorOpen ? selectedProductImages.length : selectedCompanyLogos.length) {

                                        if (isProductSelectorOpen) {
                                            dispatch(setNewContent({
                                                ...newContent,
                                                productFileId: selectedProductImages[0].id
                                            }))
                                        } else {
                                            dispatch(setNewContent({
                                                ...newContent,
                                                logoFileId: selectedCompanyLogos[0].id
                                            }))
                                        }
                                        backToSelection();
                                    }
                                }}>Add Selected</Button>
                            </div>

                        </>}
                    </>}

                {step === 2 && <LoopDetails
                    isInSide
                    isCreative={false}
                    newLoopCreated
                    contentCreationDataID={contentCreationDataID}
                    moveContent={(images) => {
                        props.closeSideModal()
                        props.setGeneratedAIImages(images)
                        //setCreatedAIImages(images)
                    }}
                />
                }
            </>}
            {activeTab === '2' && <>
                {step === 0 && <div className={'flex flex-wrap'}>
                    {creativeList.map(creative => (
                        <CreatePresetContentCard
                            isInSide
                            onClick={() => {
                                dispatch(
                                    setNewContent({
                                        ...newContent,
                                        medium: Mediums.filter(m => m.contentType === ContentType.image && m.isCustom === true).at(0),
                                        contentType: ContentType.image,
                                        wizardView: ContentWizardView.contentDetails,
                                        contentSize: ContentSize[creative.contentSize],
                                        imageHeight: creative.imageHeight,
                                        imageWidth: creative.imageWidth,
                                        loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                                        brand: {id: lastSelectedBrandId},
                                        isCreative: true,
                                    } as INewContent)
                                );
                                setStep(1)
                            }}
                            title={creative.title}
                            sampleImagePath={creative.sampleImagePath}
                        />
                    ))}
                </div>}

                {step === 1 && <>
                    {(!isInspirationThemeSelectionOpen) && <ContentDetails
                        isInSide
                        setContentCreationDataID={setContentCreationDataID}
                        setIsInspirationThemeSelectionOpen={setIsInspirationThemeSelectionOpen}
                        logoSelectionList={selectedCompanyLogos}
                        productSelectionList={selectedProductImages}
                        onAudienceEdit={(data: AudienceResponse) => {
                            setEditingAudience(data);
                            dispatch(
                                setNewContent({
                                    ...newContent,
                                    wizardView: ContentWizardView.audience,
                                } as INewContent)
                            );
                        }}
                        onAudienceCreate={() => {
                            setEditingAudience(undefined);
                            dispatch(
                                setNewContent({
                                    ...newContent,
                                    wizardView: ContentWizardView.audience,
                                } as INewContent)
                            );
                        }}
                        recentlyAddedAudience={recentlyAddedAudience}
                    />}
                    {isInspirationThemeSelectionOpen &&
                        <ThemeInspirationSelector
                            backToSelection={backToSelection}/>}

                </>}
                {step === 2 && <LoopDetails
                    isInSide
                    isCreative={true}
                    newLoopCreated
                    contentCreationDataID={contentCreationDataID}
                    moveContent={(images) => {
                        props.closeSideModal()
                        props.setGeneratedAIImages(images)
                        //setCreatedAIImages(images)
                    }}
                />}
            </>}
        </div>
    )
}

export default SelectImageContent;
