import { FunctionComponent, useEffect, useState } from "react";
import UploadFileInfinite from "../../../components/UploadFile/UploadFileInfinite";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { FileProps, useLazyFindById4Query } from "../../../services/BrandApi";
import Header from "./Header";
import ControlButtons from "./ControlButtons";
import { setNewContent } from "../../../store/contentSlice";
import { ContentWizardView } from "../../../models/ContentWizardView";

interface ImageLibraryProps {
  mode: "logo" | "product" | "referred_image" | "product_photoshoot";
  selectionList: any[];
  onImagePicksUpdated: (imagePicks: FileProps[]) => void;
}

const ImageLibrary: FunctionComponent<ImageLibraryProps> = (props: ImageLibraryProps) => {
    const newContent = useAppSelector((k) => k.content.newContent);
    const dispatch = useAppDispatch();

    const [finalPicks, setFinalPicks] = useState<FileProps[]>([])

    return (<div className="flex-col w-full h-full inline-flex">
        <Header mode={props.mode} />
        <div className="flex-col w-full h-full inline-flex border-b-1">
            {props.mode !== "referred_image" &&
                <div className="w-[800px] text-neutral-400 font-normal mt-[20px] ml-[20px] mr-[20px] leading-none">{`Select up to ${props.mode === "logo" ? "2 logos" : "4 product photos"}`}</div>
            }
            <div className="self-stretch justify-start items-center m-[20px] flex-1">
                <UploadFileInfinite
                    size="library"
                    maxFileCount={24}
                    uploadedFiles={props.selectionList}
                    title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                    description="Upload .png or .jpeg files. Max 25mb."
                    filesChanged={(f) => {

                    }}
                    selectionChanged={(pickedFiles: FileProps[]) => {
                        if (pickedFiles && pickedFiles.length > 0) {
                            setFinalPicks(pickedFiles)
                        }
                    }}
                    selectionEnabled={true}
                    maxSelectionSize={props.mode === "logo" ? 2 : 4}
                    uploadDisabled={true}
                />
            </div>
        </div>
        <ControlButtons
            mode={props.mode}
            onImageSave={() => {
                props.onImagePicksUpdated(finalPicks);
                if(props.mode === "product_photoshoot") {
                    dispatch(
                      setNewContent({
                        ...newContent,
                        wizardView: ContentWizardView.aiProductPhotoshoot,
                        productFileId:
                          finalPicks[0].id || newContent?.productFileId,
                      })
                    );
                }
                else {
                    dispatch(
                      setNewContent({
                        ...newContent,
                        wizardView: ContentWizardView.contentDetails,
                      })
                    );
                }
                
            }}
        />
    </div>)
}

export default ImageLibrary;