import { LeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import SelectTextContentType from "./SubStepComponents/SelectTextContentType";

const GenerateCaptionWithAI = (props) => {
    const [selectedTextContentType, setSelectedTextContentType] = useState()

    useEffect(() => {
        setSelectedTextContentType(undefined)
        props.setGeneratedAIText(undefined)
    }, [])

    return (
        <div className={'min-w-[350px] w-full h-full'}>
            {props.title && (
                <div>
                    <div className="p-3 flex justify-between items-center">
                        <div className={'flex items-center'}>
                            <span className="text-black mr-1 cursor-pointer" onClick={props.closeSideModal}><LeftOutlined /></span>
                            <span className="text-H8"> {props.title}</span>
                        </div>
                        <div>
                            <span className="text-gray text-xs"> {props.description ?? ''}</span>
                        </div>
                    </div>
                    <hr className="bg-border mx-1" />
                </div>
            )}
            <div className={'w-full h-[calc(100%-55px)] px-2'}>
                <SelectTextContentType
                    selectedTextContentType={selectedTextContentType}
                    setSelectedTextContentType={setSelectedTextContentType}
                    setGeneratedAIText={props.setGeneratedAIText}
                    closeSideModal={props.closeSideModal}
                />
            </div>
        </div>
    )
}

export default GenerateCaptionWithAI;
