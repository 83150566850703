import { t } from "i18next";
import { FunctionComponent, MutableRefObject, RefObject, useEffect, useState } from "react";
import SvgCloseLittle from "../../../assets/IconComponents/CloseLittle";
import SvgImportIcon from "../../../assets/IconComponents/ImportIcon";
import SvgUploadIcon from "../../../assets/IconComponents/UploadIcon";
import ContentContainer from "../../../components/ContentContainer";
import InputText from "../../../components/InputText";
import PrimaryButton from "../../../components/PrimaryButton";
import TextView from "../../../components/TextView";
import { Close } from "../../../assets/IconComponents";
import SvgArrowUp from "../../../assets/IconComponents/ArrowUp";
import { FileProps, useGenerateWithAiMutation, useImportDataFromWebsiteMutation } from "../../../services/BrandApi";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { toggleLoading } from "../../../store/appSlice";
import SvgGenerateSimilarIcon from "../../../assets/IconComponents/GenerateSimilarIcon";
import { setNewBrandInfo } from "../../../store/brandSlice";
import UploadCompanyMaterials from "../../../components/UploadFile/UploadCompanyMaterial";
import BrandHubLinkToTechnicalDoc from "../BrandHubLinkToTechnicalDoc";
import SvgGenerateSimilarBlackIcon from "../../../assets/IconComponents/GenerateSimilarBlackIcon";
import { TonOfVoices } from "../../../constants/TonOfVoices";
import SvgLoading from "../../../assets/IconComponents/Loading";



interface BrandHubBrandInfoProps {
  ref: RefObject<HTMLDivElement>
  expanded: () => void
  brandAboutEntered: () => void
  errorOccured: (e: string) => void
  className?: string
}

const BrandHubBrandInfo: FunctionComponent<BrandHubBrandInfoProps> = (props: BrandHubBrandInfoProps) => {
  const [collapsed, setCollapsed] = useState(false)
  const brandInfo = useAppSelector(k => k.brand.newBrand)
  const dispatch = useAppDispatch()
  const [firstCompanyMaterial, setFirstCompanyMaterial] = useState<FileProps | undefined>();
  const [secondCompanyMaterial, setSecondCompanyMaterial] = useState<
    FileProps | undefined
  >();
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [getBrandDatasFromAI, { data: brandDataFromAI, isLoading: brandsDataLoading, error: brandDataFromAIError }] = useImportDataFromWebsiteMutation()
  const [generateAboutWithAI, { data: aboutWithAI, isLoading: aboutWithAILoading, error: generateWithAIError }] = useGenerateWithAiMutation()

  function isValidUrl(string: string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }



  useEffect(() => {
    if (brandDataFromAIError || generateWithAIError) {
      let errorString = ""

      if (brandDataFromAIError) {
        errorString = "There was an unexpected error. Please retry later." //(brandDataFromAIError as any)?.data?.message
      }

      if (generateWithAIError) {
        errorString = "There was an unexpected error. Please retry later." //(generateWithAIError as any)?.data?.message
      }

      props.errorOccured(errorString)
    }
  }, [brandDataFromAIError, generateWithAIError])

  const getBrandInfoFromAI = () => {
    if (!checkURLValid(brandInfo?.webSiteUrl ?? "")) {
      props.errorOccured("The URL Is Not In The Correct Format");
    } else {
      getBrandDatasFromAI({
        importDataFromWebsiteRequest: {
          url: brandInfo?.webSiteUrl ?? "",
        },
      });
    }
  }

  function checkURLValid(str) {
    var urlRegex =
      "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
    var url = new RegExp(urlRegex, "i");
    return str.length < 2083 && url.test(str);
  }
  const generateCompanyAboutWithAI = () => {
    generateAboutWithAI({
      withAiRequest: {
        url: brandInfo?.webSiteUrl ?? "",
        instagram: brandInfo?.instagram,
        linkedin: brandInfo?.linkedIn,
        twitter: brandInfo?.twitter
      }
    })
  }


  const dispatchCompanyMaterials = () => {
    const companyMaterials: (FileProps | undefined)[] = [];
    if (firstCompanyMaterial) {
      companyMaterials.push(firstCompanyMaterial);
    }

    if (secondCompanyMaterial) {
      companyMaterials.push(secondCompanyMaterial);
    }
    const is = companyMaterials.map((k: any) => ({
      id: k.uploadedId ?? k.id,
      name: k.name,
      uploaded: true,
    }));
    dispatch(
      setNewBrandInfo({
        ...brandInfo,
        selectedCompanyFiles: is,
      })
    );





  }

  useEffect(() => {
    if (brandInfo && isFirstTime) {
      setIsFirstTime(false);

      const selectedCompanyFiles = brandInfo.selectedCompanyFiles ?? [];

      if (selectedCompanyFiles.length > 0) {
        // If there is at least one file, assign the first file to firstCompanyMaterial.
        setFirstCompanyMaterial(selectedCompanyFiles[0]);
      }

      if (selectedCompanyFiles.length > 1) {
        // If there are at least two files, assign the second file to secondCompanyMaterial.
        setSecondCompanyMaterial(selectedCompanyFiles[1]);
      }
    }

  }, [brandInfo]);

  useEffect(() => {
    dispatchCompanyMaterials();
  }, [firstCompanyMaterial, secondCompanyMaterial]);


  useEffect(() => {
    if (aboutWithAI) {
      dispatch(
        setNewBrandInfo({
          ...brandInfo,
          aboutCompany: aboutWithAI.about ?? brandInfo?.aboutCompany
        }))

      props.brandAboutEntered()
    }
  }, [aboutWithAI])


  useEffect(() => {
    if (brandDataFromAI) {
      if (brandDataFromAI.twitter?.trim() === "" &&
        brandDataFromAI.instagram?.trim() === "" &&
        brandDataFromAI.linkedin?.trim() === "" &&
        brandDataFromAI.about?.trim() === "") {
        props.errorOccured("Data could not be accessed. Please check the Website URL.")
        return
      }
      dispatch(
        setNewBrandInfo({
          ...brandInfo,
          twitter: brandDataFromAI.twitter ?? brandInfo?.twitter,
          instagram: brandDataFromAI.instagram ?? brandInfo?.instagram,
          linkedIn: brandDataFromAI.linkedin ?? brandInfo?.linkedIn,
          aboutCompany: brandDataFromAI.about ?? brandInfo?.aboutCompany,
          facebook: brandDataFromAI.facebook ?? brandInfo?.facebook,
          sector: brandDataFromAI.sector ?? brandInfo?.sector,
          subSector: brandDataFromAI.sub_sector ?? brandInfo?.subSector,
          selectedCompanyPrimaryFont:
            brandDataFromAI.primary_font_file_id ??
            brandInfo.selectedCompanyPrimaryFont,
          selectedCompanySecondaryFont:
            brandDataFromAI.secondary_font_file_id ??
            brandInfo.selectedCompanySecondaryFont,
          selectedCompanyAccentFont:
            brandDataFromAI.accent_font_file_id ??
            brandInfo.selectedCompanyAccentFont,
          selectedCompanyLogos: brandDataFromAI.logo_file_id
            ? [brandDataFromAI.logo_file_id]
            : brandInfo.selectedCompanyLogos,
          brandTones: brandDataFromAI.text_tone
            ? brandDataFromAI.text_tone
              ?.split(",")
              .map(
                (k) => TonOfVoices.find((l) => l.key === k)?.value ?? ""
              ) ?? []
            : brandInfo.brandTones,
          brandColors:
            brandDataFromAI.most_freq_colors ?? brandInfo.brandColors,
        })
      );
    }
  }, [brandDataFromAI])

  useEffect(() => {
    if (brandInfo.aboutCompany && !brandInfo.id) {
      props.brandAboutEntered()
    }
  }, [brandInfo.aboutCompany])



  useEffect(() => {
    dispatch(toggleLoading(brandsDataLoading || aboutWithAILoading))
  }, [brandsDataLoading, aboutWithAILoading])



  return (
    <ContentContainer
      ref={props.ref}
      className={`mt-[30px] md:mt-[40px] w-full ${props.className}`}
      hoverable={false}
      children={
        <>
          <div className="h-[56px] w-full flex items-center border-b-1 border-border justify-between">
            <span className="text-H6 text-black ml-[15px] ">
              {t("brand-information")}
            </span>
            <button
              onClick={(e) => {
                setCollapsed(!collapsed);
                props.expanded();
              }}
            >
              <SvgArrowUp
                width={22}
                height={22}
                className={`mr-3 transition-all transform-gpu ${collapsed ? "rotate-180" : "rotate-0"
                  }`}
              />
            </button>
          </div>
          {!collapsed && (
            <div className="flex flex-col w-full p-[20px]">
              <InputText
                title={t("brand-name") ?? ""}
                value={brandInfo.brandName}
                placeholder={t("enter-brand-name") ?? ""}
                innerClassName="rounded-[4px]"
                onChange={(e) => {
                  console.log(`set new brand name ${e}`)
                  dispatch(setNewBrandInfo({ ...brandInfo, brandName: e }));
                }}
              />
              <div className="flex flex-col md:flex-row items">
                <InputText
                  defaultValue={brandInfo.webSiteUrl}
                  value={brandInfo.webSiteUrl}
                  placeholder={t("enter-website-url") ?? ""}
                  className="pt-[20px]"
                  innerClassName="rounded-[4px]"
                  title={t("website-url") ?? ""}
                  onChange={(e) => {
                    if (
                      !e.toLowerCase().startsWith("http://") &&
                      !e.toLowerCase().startsWith("https://")
                    ) {
                      dispatch(
                        setNewBrandInfo({
                          ...brandInfo,
                          webSiteUrl: `https://${e}`,
                        })
                      );
                    } else {
                      dispatch(
                        setNewBrandInfo({ ...brandInfo, webSiteUrl: e })
                      );
                    }
                  }}
                  onBlur={(e) => {
                    if (
                      !e.toLowerCase().startsWith("http://") &&
                      !e.toLowerCase().startsWith("https://")
                    ) {
                      dispatch(
                        setNewBrandInfo({
                          ...brandInfo,
                          webSiteUrl: `https://${e}`,
                        })
                      );
                    }
                  }}
                />
                <PrimaryButton
                  isDisabled={
                    brandInfo?.webSiteUrl == undefined ||
                    brandInfo?.webSiteUrl == "" ||
                    !isValidUrl(brandInfo.webSiteUrl)
                  }
                  onClick={() => {
                    getBrandInfoFromAI();
                  }}
                  icon={
                    !brandsDataLoading ? (
                      <SvgImportIcon fill="#fff" className="mr-2" />
                    ) : (
                      <SvgLoading fill="#fff" className="mr-2" />
                    )
                  }
                  title={
                    !brandsDataLoading
                      ? t("import-data-from-website")
                      : t("importing-please-wait")
                  }
                  className="h-[46px] w-full md:w-[246px] mt-[10px] md:mt-[48px] md:ml-2"
                />
              </div>

              <div className="flex flex-col 2xl:flex-row mt-[20px]">
                <div className="flex flex-col md:flex-row">
                  <InputText
                    title={t("twitter") ?? ""}
                    value={brandInfo?.twitter}
                    placeholder={t("enter-your-twitter-handle") ?? ""}
                    innerClassName="rounded-[4px]"
                    onChange={(e) => {
                      dispatch(setNewBrandInfo({ ...brandInfo, twitter: e }));
                    }}
                  />

                  <InputText
                    className="max-md:mt-2 md:ml-[10px]"
                    value={brandInfo?.linkedIn}
                    title={t("linkedin") ?? ""}
                    innerClassName="rounded-[4px]"
                    placeholder={t("enter-your-linkedin-url") ?? ""}
                    onChange={(e) => {
                      dispatch(setNewBrandInfo({ ...brandInfo, linkedIn: e }));
                    }}
                  />
                </div>
                <div className="flex flex-col md:flex-row max-2xl:mt-3">
                  <InputText
                    className="2xl:ml-[10px]"
                    value={brandInfo?.instagram}
                    title={t("instagram") ?? ""}
                    innerClassName="rounded-[4px]"
                    placeholder={t("enter-your-instagram-handle") ?? ""}
                    onChange={(e) => {
                      dispatch(setNewBrandInfo({ ...brandInfo, instagram: e }));
                    }}
                  />
                  <InputText
                    className="max-md:mt-2 md:ml-[10px]"
                    value={brandInfo?.facebook}
                    title={t("facebook") ?? ""}
                    innerClassName="rounded-[4px]"
                    placeholder={t("enter-your-facebook-handle") ?? ""}
                    onChange={(e) => {
                      dispatch(setNewBrandInfo({ ...brandInfo, facebook: e }));
                    }}
                  />
                </div>
              </div>
              <div className="relative flex flex-col mt-[20px]">
                {/*
                  <button
                  className="absolute top-10 border-1 rounded-[3px] p-2 right-2 flex items-end items-center"
                  onClick={() => {
                    generateCompanyAboutWithAI();
                  }}
                >
                  <SvgGenerateSimilarBlackIcon />
                  <p className="ml-2 text-H8 text-black">Generate with AI</p>
                </button>
                  */}
                <button
                  className="absolute -top-1 right-2 flex items-end  rounded-[3px] p-1 items-center"
                  onClick={() => {
                    generateCompanyAboutWithAI();
                  }}
                >
                  <SvgGenerateSimilarIcon />
                  <p className="ml-2 text-H8 text-red">Generate with AI</p>
                </button>
                <TextView
                  initialValue={brandInfo?.aboutCompany}
                  className="w-full h-[300px]"
                  placeholder={t("enter-about-company") ?? ""}
                  title={t("company-summary") ?? ""}
                  innerClassName="rounded-[4px]"
                  onChange={(e) => {
                    dispatch(
                      setNewBrandInfo({ ...brandInfo, aboutCompany: e })
                    );
                  }}
                  onBlur={() => {
                    props.brandAboutEntered();
                  }}
                />
              </div>
              <p className="text-H8 text-black mt-[20px]">
                {
                  "Add up to 2 Company Materials (Ex. Intro Deck, Business Plan, Technical Docs)"
                }
              </p>
              <div className="flex flex-col md:flex-row mt-2 ">
                <UploadCompanyMaterials
                  title="Add up to 2 Company Materials (Ex. Intro Deck, Business Plan, Technical Docs)"
                  description="Upload .pdf, .doc or .txt files. Max 25mb."
                  maxFileCount={2}
                  index={0}
                  uploadedFile={
                    firstCompanyMaterial
                  }
                  maxFileSize={25000000}
                  fileDeleted={(index) => {
                    setFirstCompanyMaterial(undefined);
                  }}
                  fileChanged={(file, index) => {
                    setFirstCompanyMaterial(file);
                  }}
                />
                <UploadCompanyMaterials
                  title="Add up to 2 Company Materials (Ex. Intro Deck, Business Plan, Technical Docs)"
                  description="Upload .pdf, .doc or .txt files. Max 25mb."
                  maxFileCount={2}
                  maxFileSize={25000000}
                  index={1}
                  fileDeleted={(index) => {
                    setSecondCompanyMaterial(undefined);
                  }}
                  uploadedFile={
                    secondCompanyMaterial
                  }
                  fileChanged={(file, index) => {
                    setSecondCompanyMaterial(file);
                  }}
                />
              </div>
              <BrandHubLinkToTechnicalDoc
                errorOccured={(e) => {
                  props.errorOccured(e);
                }}
              />
            </div>
          )}
        </>
      }
    />
  );
}

export default BrandHubBrandInfo;
