import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useLazyDownloadQuery, useUploadMutation } from "../services/FileApi";
import { blobToDataURL, dataURLToBlob } from "blob-util";
import PrimaryButton from "./PrimaryButton";
import SvgCheckers from "../assets/IconComponents/Checkers";
import SvgErrorIcon from "../assets/IconComponents/ErrorIcon";
import SvgUploadIcon from "../assets/IconComponents/UploadIcon";
import { useRemoveBackgroundMutation } from "../services/ImageopsApi";
interface RevisionImageEditorProps {
    title?: string | null
    className?: string
    imageFileId?: string
    editorType: "ai" | "logo" | "product"
    onGenerateWithAiClicked?: () => void
    onImageChanged?: (file: FileProps) => void
    onImageDeleted?: () => void
    onImageOpsProgress?: (boolean) => void
    isDisabled?: boolean
}

interface FileProps {
    uploadedId: string
    name: string
    type: string
    preview: string
    imageId?: string
    adId?: string
}

const RevisionImageEditor: FunctionComponent<RevisionImageEditorProps> = (props: RevisionImageEditorProps) => {

    const changeImageRef = useRef<HTMLInputElement | null>(null);
    const [imageFile, setImageFile] = useState<FileProps | null>(null);
    const [error, setError] = useState("");
    const [
        downloadFile,
    ] = useLazyDownloadQuery();
    const [removeBackground, { data: backgroundRemovedImage, isLoading: backgroundRemovalInProgress }] = useRemoveBackgroundMutation();
    const [
        uploadFiles, { isLoading: uploadInProgress }
    ] = useUploadMutation();

    useEffect(() => {
        if (backgroundRemovedImage?.backgroundRemovedFile?.id && props.onImageChanged) {
            const backgroundRemovedFile = backgroundRemovedImage?.backgroundRemovedFile
            props.onImageChanged({
                uploadedId: backgroundRemovedFile.id ?? "",
                name: backgroundRemovedFile.name ?? "",
                type: "image",
                preview: "",
            })
        }
    }, [backgroundRemovedImage]);

    useEffect(() => {
        if (props.onImageOpsProgress) {
            props.onImageOpsProgress(backgroundRemovalInProgress || uploadInProgress)
        }
    }, [backgroundRemovalInProgress, uploadInProgress, props.onImageOpsProgress])

    useEffect(() => {
        if (
            props.imageFileId
        ) {
            downloadFileFrom(props.imageFileId).then((k) => {
                setImageFile(k);
            });
        }
        else {
            setImageFile(null);
        }
    }, [props.imageFileId]);

    const downloadFileFrom = async (fileId: string) => {
        return new Promise<any>((resolve) => {
            downloadFile({
                id: fileId,
            })
                .unwrap()
                .then(async (k) => {
                    const item = {
                        uploadedId: fileId,
                        name: k.name,
                        type: k.file.type.includes("ima") ? "image" : "other",
                        preview: await blobToDataURL(k.file),
                    };

                    return resolve(item);
                });
        });
    };

    const handleFilesChanged = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            selectFile(e.target.files[0]);
        }
    };

    const fileToBlob = (file?: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (!!reader.result) {
                    resolve(reader.result);
                } else {
                    reject(Error("Failed converting to base64"));
                }
            };
        });
    };

    const selectFile = async (file: any) => {
        let fileProcessed = Object.assign(file, {
            preview: URL.createObjectURL(file)
        });
        let a = (await fileToBlob(fileProcessed)) as string;
        let blob = dataURLToBlob(a);
        const formData: FormData = new FormData();
        formData.append("files", blob, file.name);
        uploadFiles({
            body: formData,
        })
            .unwrap()
            .then((k) => {
                file["uploadedId"] = k.ids ?? "";
                setImageFile(file);
                if (props.onImageChanged) {
                    props.onImageChanged(file)
                }
                // dispatch(toggleLoading(false));
            })
            .catch((error) => {
                setError("File couldn't be uploaded");
                // dispatch(toggleLoading(false));
            });
    };


    return (
        <div className={`w-[280px] h-[111px] justify-start items-start gap-2.5 flex ${props.className}`}>
            <div className="grow shrink basis-0 text-H6 text-black ">{props.title}</div>
            <div className="justify-start items-start gap-2.5 flex h-full">
                <div className="flex flex-col nogrow w-[70px] justify-start items-end gap-1.5">
                    <div className=" flex flex-row justify-start items-start gap-1.5">
                        {
                            // (props.editorType === "ai" &&
                            //     <PrimaryButton
                            //         className="w-8 h-8 rounded-md border-stone-200  relative border-1 border-border radius-[6px]"
                            //         isDisabled={props.isDisabled}
                            //         icon={<SvgGenerateSimilarWhiteIcon />}
                            //         tooltip="Generate With AI"
                            //         onClick={() => {
                            //             props.onGenerateWithAiClicked && props.onGenerateWithAiClicked()
                            //         }}
                            //     />
                            // )
                            // ||
                            (
                                (props.editorType === "logo" || props.editorType === "product") &&
                                <PrimaryButton
                                    isDisabled={props.isDisabled}
                                    icon={<SvgCheckers fill="#FFFFFF" />}
                                    noFill
                                    className="w-8 h-8 rounded-md border-stone-200  relative border-1 border-border radius-[6px]"
                                    tooltip="Remove Background"
                                    onClick={() => {
                                        if (imageFile) {
                                            removeBackground({ body: { imageFileId: imageFile?.uploadedId } });
                                        }
                                    }}
                                />
                            )
                        }
                        <input
                            type="file"
                            ref={changeImageRef}
                            accept="image/jpeg, image/jpg, image/png, image/svg+xml"
                            style={{ display: "none" }}
                            onChange={(event) => handleFilesChanged(event)}
                        />
                        <PrimaryButton
                            icon={<SvgUploadIcon fill="#000000" />}
                            noFill
                            className="w-8 h-8 rounded-md border-stone-200  relative border-1 border-border radius-[6px]"
                            isDisabled={props.isDisabled}
                            tooltip="Change Image"
                            onClick={() => {
                                if (changeImageRef.current) {
                                    changeImageRef.current.click();
                                }
                            }}
                        />
                    </div>
                    {/* <PrimaryButton
                        icon={<SvgDelete fill="#000000" />}
                        noFill
                        className="w-8 h-8 rounded-md border-stone-200  relative border-1 border-border radius-[6px]"
                        isDisabled={props.isDisabled}
                        tooltip="Delete Image"
                        onClick={() => {
                            props.onImageDeleted && props.onImageDeleted()
                        }}
                    /> */}
                </div>
                <div className="flex grow w-[70px] h-full relative">
                    {(imageFile && <img
                        src={imageFile?.preview ?? ""}
                        alt={imageFile?.name ?? ""}
                        className="w-[70px] h-full left-0 top-0 absolute rounded-md border border-stone-200 object-contain"
                    />)}
                </div>
            </div>
            {error && (
                <div className="flex flex-row mt-2 items-start">
                    <SvgErrorIcon
                        width="20"
                        height="20"
                        fill="#FF5555"
                        className="mr-2"
                    />
                    <text className="text-red text-BodySmall">{error}</text>
                </div>
            )}
        </div>
    );
}

export default RevisionImageEditor;
