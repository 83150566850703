import { FileProps } from "./BrandApi";
import { coreApi as api } from "./Core/BaseApi";

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        removeBackground: build.mutation<RemoveBackgroundApiResponse, RemoveBackgroundApiArg>({
            query: (queryArg) => ({
                url: `/imageops/removebg`,
                method: "POST",
                body: queryArg.body,

            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type RemoveBackgroundApiResponse = /** status 200 OK */ RemoveBackgroundReponse;
export type RemoveBackgroundApiArg = {
    body: RemoveBackground;
};

export type RemoveBackground = {
    imageFileId?: string;
};

export type RemoveBackgroundReponse = {
    backgroundRemovedFile?: FileProps;
};
export const {
    useRemoveBackgroundMutation
} = injectedRtkApi;
