import {useLazyDownloadImageQuery} from "../../../services/FileApi";
import {blobToDataURL} from "blob-util";
import {useEffect, useState} from "react";
import SecondaryButton from "../../SecondaryButton";
import SvgGenerateSimilarIcon from "../../../assets/IconComponents/GenerateSimilarIcon";
import { FileImageOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import IconButton from "../../IconButton";
import SvgDownload from "../../../assets/IconComponents/Download";
import {Delete} from "../../../assets/IconComponents";
import {toggleLoading} from "../../../store/appSlice";
import {useAppDispatch} from "../../../helpers/hooks";
const OrganicImages = (props) => {

    const [downloadImage] = useLazyDownloadImageQuery();
    const dispatch = useAppDispatch();

    const [downloadedImages, setDownloadedImages] = useState<any[]>([]);
    const [selectedImagePreview, setSelectedImagePreview] = useState<any>();

    useEffect(() => {
        if(props.setActiveImage && selectedImagePreview?.id){
            props.setActiveImage(selectedImagePreview?.id)
        }
    }, [selectedImagePreview]);


    useEffect(() => {
        dispatch(toggleLoading(true));
        props.images.forEach(img => {
            downloadFileFrom(img)
        })
        if (selectedImagePreview && !props.images.filter(img => img.imageFileId === selectedImagePreview.id).length) {
            setSelectedImagePreview(undefined);
        }
    }, [props.images]);

    const downloadImageToLocal = (img) => {
        const link = document.createElement("a");
        link.href = img.preview;
        link.download = (img.name ?? "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        if (downloadedImages.length===props.images) {
            dispatch(toggleLoading(false));
        }
    }, [downloadedImages]);

    const downloadFileFrom = async (image) => {
        new Promise<any>((resolve) => {
            downloadImage({
                id: image.imageFileId
            })
                .unwrap()
                .then(async (k) => {
                    dispatch(toggleLoading(false));
                    const item = {
                        uid: image.imageFileId,
                        id: image.imageFileId,
                        active: image.active,
                        name: k.type,
                        status: 'done',
                        preview: await blobToDataURL(k),
                        isImageUrl: false,
                        url: '{download: "image"}',
                        isAI: false
                    };
                    setDownloadedImages(prevState => {
                        if (!downloadedImages.filter(img => img.id === image.imageFileId).length) {
                            return [...prevState, item]
                        }
                        return prevState
                    })
                    if (!selectedImagePreview) {
                        if (image.active) {
                            setSelectedImagePreview(item)
                        } else {
                            if (!props.images.filter(i => i.active).length) {
                                if(props.setActiveImage){
                                    props.setActiveImage(item.id)
                                }

                                setSelectedImagePreview(item)
                            }
                        }
                    }
                })
                .catch((e) => {
                    console.log("error", e);
                });
        });
    };


    const handleArrowClick = (type: string) => {
        const newImageIndex = type === 'next'? selectedImagePreview.index + 1 : selectedImagePreview.index - 1
        if (newImageIndex >= 0 && downloadedImages[newImageIndex]) {
            if(props.setActiveImage){
                props.setActiveImage(downloadedImages[newImageIndex].id)
            }
            setSelectedImagePreview({...downloadedImages[newImageIndex], index: newImageIndex})
        }
    }

    useEffect(() => {

        if (!selectedImagePreview && downloadedImages.length) {
            if(props.setActiveImage){
                props.setActiveImage(downloadedImages[0].id)
            }

            setSelectedImagePreview({...downloadedImages[0], index: 0})
        }
    }, [downloadedImages]);

    return (
        <div>
            <div>
                {selectedImagePreview && (
                    <div className={'relative'}>
                        <div className={'absolute flex'} style={{top: '20px', right: '15px'}}>
                            <div className={'flex justify-center items-center w-[30px] h-[30px] bg-white rounded-[6px] mr-2'}>
                                <IconButton
                                className=""
                                title={''}
                                icon={SvgDownload}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    downloadImageToLocal(selectedImagePreview);
                                }}
                            />
                            </div>
                            <div className={'flex justify-center items-center w-[30px] h-[30px] bg-white rounded-[6px]'}>
                                <IconButton
                                    className=""
                                    title={''}
                                    icon={Delete}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDownloadedImages(k => k.filter(l => l.id !== selectedImagePreview.id))
                                        props.deleteItemImage(selectedImagePreview.id); //add this
                                    }}
                                />
                            </div>
                        </div>
                        <div className={'absolute'} style={{top: 'calc(50% - 16px)', left: '15px'}}>
                            <div style={{backgroundColor: 'rgba(100, 100, 100, 0.6)', height: '32px', width: '32px'}}
                                 onClick={() => handleArrowClick('prev')}
                                 className={'rounded-[50%] flex items-center justify-center cursor-pointer'}>
                                <LeftOutlined className={'text-white'}/>
                            </div>
                        </div>
                        <div className={'absolute'} style={{top: 'calc(50% - 16px)', right: '15px'}}>
                            <div style={{backgroundColor: 'rgba(100, 100, 100, 0.6)', height: '32px', width: '32px'}}
                                 onClick={() => handleArrowClick('next')}
                                 className={'rounded-[50%] flex items-center justify-center cursor-pointer'}>
                                <RightOutlined className={'text-white'}/>
                            </div>
                        </div>
                        <div  style={{ width:'320px', height:'320px'}}>
                        <img
                            className={'rounded-[5px] h-[310px] w-[310px] max-h-[310px] object-contain '}
                            src={selectedImagePreview.preview}
                        />
                        </div>
                        <div className={'absolute'} style={{top: 'calc(90% - 16px)', left: '15px'}}>
                            <div style={{backgroundColor: 'rgba(100, 100, 100, 0.6)', height: '32px', width: '32px'}}
                                 onClick={() => handleArrowClick('prev')}
                                 className={'rounded-[5px] flex items-center justify-center cursor-pointer text-white'}>
                                {(selectedImagePreview.index ?? 0) + 1}/{props.images?.length}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={'flex flex-row overflow-x-auto  max-w-[320px] mt-1'}>

                {downloadedImages.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i).map((img, i) => (
                    <img
                        className={`h-[90px] w-[90px] object-contain mr-1 rounded-[5px] border-1 ${selectedImagePreview?.id === img.id ? 'border-red' : ''}`}
                        onClick={() => setSelectedImagePreview({...img, index: i})}
                        title={img.name}
                        src={img.preview}
                    />
                ))}
                <div className={'inline-block ml-1'}>
                    <div className={'flex flex-col items-center justify-center w-[90px] h-[90px] border-1 rounded-[5px]'}>
                        <SecondaryButton
                            noFill
                            className="h-[35px] w-[35px] border-1 border-border radius-[6px] p-1 block mb-1"
                            icon={<FileImageOutlined />}
                            title={''}
                            onClick={()=>props.openSide('custom')}
                        />
                        <SecondaryButton
                            className="h-[35px] w-[35px] border-1 border-border radius-[6px] p-1 block"
                            icon={<SvgGenerateSimilarIcon/>}
                            title={''}
                            onClick={()=>props.openSide('AI')}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OrganicImages;
