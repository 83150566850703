import * as React from "react";
import type { SVGProps } from "react";
const SvgPartyBaloon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="Layer_1" clip-path="url(#clip0_4789_6014)">
            <path id="Vector" opacity="0.5" d="M3.25425 24.6126C2.18574 24.6086 1.364 23.7548 1.4159 22.7534C1.43727 22.3464 1.57363 21.9582 1.71203 21.5765C2.28869 19.989 2.86738 18.4025 3.44811 16.8171C3.91469 15.545 4.38993 14.2766 4.85905 13.0055C4.99993 12.628 5.08179 12.2311 5.10176 11.8287C5.11957 11.4567 5.13941 11.0843 5.18011 10.7144C5.20301 10.5068 5.3521 10.3582 5.50983 10.233C5.71837 10.0697 5.97561 9.98096 6.24049 9.98096C6.50537 9.98096 6.76261 10.0697 6.97115 10.233C7.41852 10.589 7.83658 10.9804 8.2213 11.4033C8.38056 11.5732 8.54694 11.7366 8.7118 11.9014L15.4551 18.6447C15.736 18.9251 15.9533 19.2375 16.0255 19.641C16.1308 20.2307 15.6963 20.8148 15.1046 20.8779C14.7794 20.9125 14.4538 20.8993 14.1286 20.9186C13.8841 20.9314 13.6425 20.9786 13.4112 21.0591C11.9631 21.5709 10.5161 22.0833 9.07815 22.6232C7.40652 23.2507 5.72845 23.8599 4.04393 24.4508C3.7908 24.5458 3.52433 24.6004 3.25425 24.6126Z" fill="#FF5555" />
            <path id="Vector_2" d="M22.8329 3.85101C22.6803 3.85101 22.5002 3.85457 22.3241 3.85101C22.231 3.85101 22.1842 3.86983 22.1862 3.97617C22.1893 4.19598 22.1806 4.41681 22.1776 4.63763C22.172 5.08743 22.1557 5.53773 21.9908 5.96055C21.7568 6.56604 21.3467 7.00922 20.7402 7.25854C20.4655 7.37018 20.1853 7.46749 19.9006 7.55009C19.8682 7.55707 19.8385 7.57319 19.8149 7.59655C19.7914 7.6199 19.7751 7.64952 19.7678 7.68187C19.6727 7.99836 19.5816 8.31739 19.4351 8.61504C19.0789 9.3401 18.5156 9.78176 17.698 9.8769C17.4112 9.90209 17.126 9.94285 16.8437 9.99902C16.5897 10.0537 16.3565 10.1794 16.171 10.3613C15.9704 10.5566 15.76 10.7415 15.5406 10.9154C14.846 11.4669 13.8177 11.0772 13.6503 10.2031C13.5852 9.86215 13.6427 9.55228 13.8569 9.26938C14.1912 8.82874 14.5469 8.41151 15.0002 8.08536C15.5955 7.65459 16.318 7.43569 17.0523 7.46359C17.1764 7.46665 17.2278 7.43052 17.2599 7.31095C17.3214 7.07842 17.3998 6.84996 17.4787 6.62252C17.7142 5.9402 18.1778 5.48379 18.8591 5.24872C19.1069 5.16426 19.3572 5.08641 19.6086 5.01161C19.6389 5.00683 19.6662 4.99073 19.6851 4.96655C19.704 4.94237 19.713 4.91192 19.7103 4.88136C19.7266 4.50026 19.7205 4.11813 19.7388 3.73754C19.7968 2.53826 20.6038 1.62748 21.7904 1.4321C22.3099 1.34662 22.8278 1.35781 23.3484 1.4036C23.4793 1.40728 23.6097 1.42226 23.7381 1.44838C24.2846 1.59441 24.6479 2.12714 24.6117 2.725C24.5797 3.2618 24.1462 3.71515 23.5911 3.79911C23.3397 3.83269 23.0865 3.85003 22.8329 3.85101Z" fill="#FF5555" />
            <path id="Vector_3" d="M11.1552 7.56019C11.1552 7.98403 11.172 8.40838 11.1501 8.83223C11.1272 9.27896 10.9008 9.61733 10.4973 9.82035C10.0938 10.0234 9.69997 9.99691 9.30869 9.77252C8.93674 9.55831 8.7958 9.21282 8.74492 8.81645C8.68539 8.35241 8.73983 7.88481 8.71693 7.41975C8.69811 7.04476 8.70116 6.66416 8.53478 6.31359C8.2071 5.62466 7.68506 5.20234 6.90657 5.11737C6.50563 5.07361 6.10264 5.07971 5.7017 5.04715C5.47341 5.03462 5.24735 4.99569 5.02803 4.93114C4.31568 4.70167 3.83333 4.0112 3.83842 3.22814C3.843 2.4293 4.31111 1.75817 5.04482 1.50275C5.25133 1.4396 5.46507 1.40318 5.68084 1.39437C7.12282 1.28395 8.41826 1.66048 9.51018 2.62316C10.4082 3.41436 10.9486 4.41164 11.1063 5.60939C11.1879 6.25638 11.2043 6.90993 11.1552 7.56019Z" fill="#FF5555" />
            <path id="Vector_4" d="M18.6175 13.6146C19.249 13.5976 19.8808 13.626 20.5082 13.6996C21.4404 13.8212 22.2474 14.2242 22.9424 14.8485C23.8074 15.624 24.363 16.5785 24.5503 17.7299C24.652 18.3527 24.6902 18.9867 24.5381 19.61C24.4414 20.0046 24.2119 20.3538 23.8882 20.5993C23.5645 20.8447 23.1663 20.9713 22.7603 20.9579C22.113 20.9406 21.6068 20.6633 21.2394 20.1305C21.0664 19.8802 21.0216 19.5917 20.985 19.3007C20.9255 18.8168 20.9341 18.3283 20.8878 17.8429C20.8023 16.9657 20.0615 16.2462 19.2087 16.1251C18.7971 16.0666 18.3809 16.0707 17.9657 16.0743C17.6563 16.0743 17.3475 16.0799 17.0407 16.0234C16.4856 15.9216 16.0795 15.4688 16.0556 14.9472C16.0256 14.2858 16.4805 13.777 16.9776 13.6828C17.2691 13.6289 17.5683 13.6289 17.8644 13.6172C18.1168 13.607 18.3672 13.6146 18.6175 13.6146Z" fill="#FF5555" />
            <path id="Vector_5" d="M24.0098 12.3975C23.524 12.4009 23.0567 12.2111 22.7108 11.87C22.3649 11.5289 22.1687 11.0643 22.1653 10.5785C22.162 10.0927 22.3517 9.62545 22.6928 9.27955C23.034 8.93364 23.4985 8.73742 23.9843 8.73405C24.9994 8.72743 25.8283 9.53543 25.8334 10.5347C25.8385 11.5804 25.0457 12.3924 24.0098 12.3975Z" fill="#FF5555" />
            <path id="Vector_6" d="M15.4475 3.83315C15.0841 3.83311 14.7289 3.72496 14.4271 3.52247C14.1253 3.31998 13.8906 3.0323 13.7528 2.69602C13.615 2.35974 13.5803 1.99006 13.6532 1.63402C13.7261 1.27799 13.9032 0.951678 14.1621 0.696612C14.421 0.441546 14.7499 0.269252 15.107 0.201649C15.464 0.134046 15.8332 0.174189 16.1674 0.316973C16.5016 0.459757 16.7857 0.698727 16.9837 1.00348C17.1817 1.30823 17.2846 1.665 17.2792 2.02838C17.278 2.2673 17.2295 2.50362 17.1367 2.72378C17.0439 2.94393 16.9085 3.14358 16.7383 3.31127C16.5681 3.47896 16.3664 3.61139 16.1449 3.70094C15.9234 3.7905 15.6864 3.83543 15.4475 3.83315Z" fill="#FF5555" />
            <path id="Vector_7" d="M1.99642 6.29171C2.35853 6.29172 2.71251 6.39904 3.01365 6.60014C3.31479 6.80123 3.54957 7.08707 3.68833 7.42154C3.82709 7.75601 3.8636 8.1241 3.79325 8.47931C3.7229 8.83452 3.54885 9.16091 3.29308 9.41724C3.03732 9.67358 2.71131 9.84835 2.35626 9.91949C2.00121 9.99063 1.63304 9.95493 1.29826 9.81692C0.963484 9.6789 0.677126 9.44476 0.475364 9.14407C0.273602 8.84338 0.165489 8.48963 0.164684 8.12752C0.161758 7.88597 0.207111 7.64627 0.298077 7.42249C0.389043 7.1987 0.523791 6.99534 0.694415 6.82434C0.865039 6.65333 1.0681 6.51814 1.29168 6.42667C1.51526 6.33521 1.75486 6.28933 1.99642 6.29171Z" fill="#FF5555" />
            <path id="Vector_8" d="M20.9442 24.0336C20.9315 25.0625 20.1143 25.8735 19.1049 25.8588C18.7426 25.8573 18.389 25.7484 18.0886 25.5459C17.7882 25.3434 17.5547 25.0564 17.4174 24.7212C17.2801 24.386 17.2453 24.0176 17.3174 23.6626C17.3895 23.3076 17.5652 22.9819 17.8223 22.7268C18.0794 22.4716 18.4065 22.2985 18.762 22.2292C19.1176 22.1598 19.4857 22.1975 19.8198 22.3374C20.1539 22.4773 20.4391 22.7131 20.6393 23.015C20.8394 23.3169 20.9455 23.6714 20.9442 24.0336Z" fill="#FF5555" />
        </g>
        <defs>
            <clipPath id="clip0_4789_6014">
                <rect width="25.6667" height="25.6921" fill="white" transform="translate(0.166748 0.166626)" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgPartyBaloon;
