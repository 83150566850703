import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import OnboardingChooseStyleCard from "./OnboardingChooseStyleCard";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../components/PrimaryButton";
export interface OnboardingStyle {
  name: string;
  path: string;
}
export interface IOnboardingChooseStylePageProps {
  onContinue: (string: string) => void;
}

function OnboardingChooseStylePage(props: IOnboardingChooseStylePageProps) {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState<string>("");

  const onboardingStyles: OnboardingStyle[] = [
    { name: "hobbyist", path: "hobbyist_icon" },
    { name: "content-creator", path: "content_creator" },
    { name: "small-company", path: "small_company" },
    { name: "large-company", path: "large_company" },
    { name: "marketing-agency", path: "marketing_agency" },
    { name: "non-profit", path: "non_profit" },
  ];


  return (
    <div className="relavite min-w-full h-full flex flex-col justify-between">
      <div className="w-full h-[calc(100%-70px)] flex flex-col items-center">
        <div className="flex flex-col items-center w-full px-[50px]">
          <span className="text-H2 font-medium text-black mt-[60px] ">
            {t("choose-what-best-describes-you")}
          </span>
          <span className="text-BodyMedium text-black mt-[20px] ">
            {t(
              "this-helps-everycopy-to-tailor-your-experience-offering-streamlined-access-to-features-most-relevant-to-you"
            )}
          </span>
          <div
            className={`mt-[50px] w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-[8px] gap-y-[10px]`}
          >
            {onboardingStyles.map((item) => {
              return (
                <OnboardingChooseStyleCard
                  isSelected={item.name === selectedItem}
                  item={item}
                  toggleItem={() => {
                    setSelectedItem(item.name);
                    props.onContinue(selectedItem);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className=" justify-end flex flex-row right-0 bottom-0 bg-white w-full h-[50px] py-1">
        <PrimaryButton
          noFill
          className="w-16 h-11 mr-2"
          title={t("skip")}
          onClick={() => {
            props.onContinue("");
          }}
        />
      </div>
    </div>
  );
}

export default OnboardingChooseStylePage;
