import {t} from "i18next";
import {FunctionComponent, useEffect, useRef} from "react";
import PrimaryButton from "../../components/PrimaryButton";
import {useAppDispatch, useAppSelector} from "../../helpers/hooks";
import {toggleDialog, toggleLoading} from "../../store/appSlice";
import {useState} from "react";
import ContentContainer from "../../components/ContentContainer";
import {Add} from "../../assets/IconComponents";
import {setSavedData} from "../../store/plannerSlice";
import Gantt from "../../components/Gantt";
import Toolbar from "../../components/Toolbar";
import MarketingPlannerModalOpener from "../../components/MarketingPlanner/MarketingPlannerModalOpener";
import GanttEditModal from "../../components/Gantt/GanttEditModal";
import {MarketingPlannerResponse, useLazyGetAllMarketingPlannerQuery, useSaveMarketingPlannerMutation} from "../../services/MarketingPlanner";
import dayjs from "dayjs";
import {reformatText} from "../../helpers/Utils";

const MarketingPlannerPage: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [showMarketingPlannerModal, setShowMarketingPlannerModal] = useState(false);
    const {plan, event, savedData, ganttData} = useAppSelector((k) => k.planner);
    const [currentZoom, setCurrentZoom] = useState('Weekly')
    const [isGanttEditModalOpen, setIsGanttEditModalOpen] = useState(false)
    const [isShowActiveCampaignFilter, setIsShowActiveCampaignFilter] = useState(false)
    const [selectedGanttItem, setSelectedGanttItem] = useState<any>()
    const [selectedMarketingItem, setSelectedMarketingItem] = useState<any>()
    const [ganttItems, setGanttItems] = useState<any[]>()
    const ganttRef = useRef({});
    const initGanttRef = useRef();
    const [getAll, { data: createdData, isLoading:createIsLoading }] = useLazyGetAllMarketingPlannerQuery()

    const lastSelectedBrandId = useAppSelector(k => k.persisted.lastSelectedBrandId)

    useEffect(() => {
        dispatch(toggleLoading(createIsLoading));
    }, [createIsLoading]);


    useEffect(() => {
        // @ts-ignore
        getAll({brandId:lastSelectedBrandId}).then(aa=> {
            //console.log("getAll",aa)
            dispatch(setSavedData(aa))

        })
    }, [lastSelectedBrandId])

    useEffect(() => {
        if(isShowActiveCampaignFilter){
            const data = savedData.filter((item) =>  dayjs(item.endDate) >= dayjs(new Date()))
            populateGantTasks(data)
        }
        else {
            populateGantTasks(savedData)
        }

    }, [isShowActiveCampaignFilter])

    useEffect(() => {
        // @ts-ignore
        if (ganttRef.current?.clearAll) {
            // @ts-ignore
            ganttRef.current?.clearAll()
        }
         // @ts-ignore
         if (ganttRef.current?.refreshData) {
            // @ts-ignore
            ganttRef.current?.refreshData()
        }
        if (savedData?.length) {
            if(isShowActiveCampaignFilter){
                const data = savedData.filter((item) =>  dayjs(item.endDate) >= dayjs(new Date()))
                populateGantTasks(data)
            }
            else {
                populateGantTasks(savedData)
            }
        }
    }, [savedData]);

    function populateGantTasks(listData: MarketingPlannerResponse[]) {
        const data = listData.map((item, i) => {
            const ganttObj = {
                ...item,
                id: item.id,
                text: reformatText(item.headerType === "EVENT" ? item.eventType : item.campaignType),
                activity: item.activitySize,
                start_date: dayjs(item.startDate).format('YYYY-MM-DD'),
                end_date: dayjs(item.endDate).format('YYYY-MM-DD')
            } as any

            if (item.rootId !== item.id) {
                listData.forEach((baseItem , index) => {
                    if (item.rootId === baseItem.id) {
                        ganttObj.parent = baseItem.id
                    }
                })

            }
            if (item.rootId === item.id) {
                ganttObj.open = true
            }

            return ganttObj as MarketingPlannerResponse
        })

        // @ts-ignore
        if (ganttRef.current?.parse) {
            // @ts-ignore
            ganttRef.current?.parse({data:data})
        }

        setGanttItems(data)

        // @ts-ignore
        if (ganttRef.current?.refreshData) {
            // @ts-ignore
            ganttRef.current?.refreshData()
        }
    }

    return (
        <>
            <MarketingPlannerModalOpener
                open={showMarketingPlannerModal}
                close={() => {
                    setShowMarketingPlannerModal(false);
                    dispatch(toggleDialog(false));
                }}
                submit={() => {
                    setShowMarketingPlannerModal(false);
                    dispatch(toggleDialog(false));
                }}
                title={`${t("add-new")}`}
                hideFooter={true}
            />
            {
            isGanttEditModalOpen &&
                <GanttEditModal isOpen={isGanttEditModalOpen} selectedItem={selectedGanttItem} ganttRef={ganttRef} close={() => setIsGanttEditModalOpen(false)} isEditMode={false}  marketingData={selectedMarketingItem}    />
            }
            <div
                className="relative min-w-full h-[56px] text-xl flex flex-row bg-white border-1 border-border items-center justify-between">
                <span className="text-H6 text-black px-5">{t("marketing-planner")}</span>
            </div>
            <div className="h-[calc(100vh-140px)] md:h-[calc(100vh-60px)] pl-3 pr-3" style={{width:'99%'}}>
                <div className="flex w-full scrollbar-hide flex-col overflow-scroll">
                    <ContentContainer
                        className={`mt-[20px] w-full`}
                        hoverable={false}
                        children={
                            <>
                                <div
                                    className="h-[56px] w-full flex items-center border-b-1 border-border justify-between px-4">
                                    <div>
                                        <div className="zoom-bar text-black">
                                            <Toolbar
                                                zoom={currentZoom}
                                                onZoomChange={setCurrentZoom}
                                                isFilterEnabled={true}
                                                onActiveCampaignFilterChange = {(e) => {
                                                    setIsShowActiveCampaignFilter(e.target.checked)
                                                    //console.log("filterChanged",e.target.checked)
                                                } }
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <PrimaryButton
                                            onClick={() => {
                                                //dispatch(setPlan({}));
                                                setShowMarketingPlannerModal(true);
                                            }}
                                            icon={<Add fill="#ffffff" className="mr-2"/>}
                                            title={t("add-new")}
                                            className="h-[40px] w-[146px]"
                                        />
                                    </div>
                                </div>
                                <div className="w-full text-black">
                                    <div className="gantt-container-marketingPlanner">
                                        {ganttItems && <Gantt
                                            isPreview={false}
                                            onChange={(e) => console.log(e)}
                                            tasks={{data: ganttItems}}
                                            setGantt={(ref) => {
                                                ganttRef.current = ref;
                                            }}
                                            initGantt={(ref) => {
                                                initGanttRef.current = ref;
                                            }}
                                            zoom={currentZoom}
                                            handleTaskClick={(item) => {
                                                setIsGanttEditModalOpen(true);

                                                setSelectedGanttItem(item);
                                                setSelectedMarketingItem(savedData.filter(data => data.id === item.id )?.[0] )

                                            }}
                                        />}
                                    </div>
                                </div>
                            </>
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default MarketingPlannerPage;




