import PrimaryButton from "../../components/PrimaryButton";
import ForkIcon from '../../assets/Icon/Fork.png';
import GridIcon from '../../assets/Icon/Grid.png';
import ListIcon from '../../assets/Icon/List.png';
import { useState } from "react";
import Icon from "../../components/Icon";
import { Grid, List } from "../../assets/IconComponents";

export enum SelectedLoopsPageListType {
    Grid,
    List
}

export interface ILoopsPageListTypeSelectorProps {
    onChange: (selected: SelectedLoopsPageListType) => void
}

function LoopsPageListTypeSelector(props: ILoopsPageListTypeSelectorProps) {

    const [selected, setSelected] = useState<SelectedLoopsPageListType>(SelectedLoopsPageListType.Grid)

    return (
        <div className="w-[72px] h-[42px] p-1 bg-white rounded-md border border-stone-200 justify-start items-start inline-flex">
            <PrimaryButton
                iconComponent={
                    selected === SelectedLoopsPageListType.Grid ?
                        <Icon width="20" height="20" component={Grid} color={"#ffffff"} /> :
                        <Icon width="20" height="20" component={Grid} color={"#999999"} />
                }
                noFill={selected != SelectedLoopsPageListType.Grid}
                className={`w-8 h-8 p-2 rounded justify-start items-start gap-2.5 ${selected != SelectedLoopsPageListType.Grid ? `!border-none` : ``}`}
                onClick={() => {
                    setSelected(SelectedLoopsPageListType.Grid)
                    props.onChange(SelectedLoopsPageListType.Grid)
                }} />
            <PrimaryButton
                iconComponent={
                    selected === SelectedLoopsPageListType.Grid ?
                        <Icon width="20" height="20" component={List} color={"#999999"} /> :
                        <Icon width="20" height="20" component={List} color={"#ffffff"} />
                }
                noFill={selected != SelectedLoopsPageListType.List}
                className={`w-8 h-8 p-2 rounded-md justify-start items-start gap-2.5 ${selected != SelectedLoopsPageListType.List ? `!border-none` : ``}`}
                onClick={() => {
                    setSelected(SelectedLoopsPageListType.List)
                    props.onChange(SelectedLoopsPageListType.List)
                }} />
        </div>
    );
}

export default LoopsPageListTypeSelector;