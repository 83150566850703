import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createText: build.mutation<CreateTextApiResponse, CreateTextApiArg>({
      query: (queryArg) => ({
        url: `/creative/text/create`,
        method: "POST",
        body: queryArg.creativeTextRequest,
      }),
    }),
    createImage: build.mutation<CreateImageApiResponse, CreateImageApiArg>({
      query: (queryArg) => ({
        url: `/creative/product/create`,
        method: "POST",
        body: queryArg.productImageRequest,
      }),
    }),
    applyProductUpdates: build.mutation<
      ApplyProductUpdatesApiResponse,
      ApplyProductUpdatesApiArg
    >({
      query: (queryArg) => ({
        url: `/creative/product/apply`,
        method: "POST",
        body: queryArg.productRevisionRequest,
      }),
    }),
    editImage: build.mutation<EditImageApiResponse, EditImageApiArg>({
      query: (queryArg) => ({
        url: `/creative/image/edit`,
        method: "POST",
        body: queryArg.photoshootEditRequest,
      }),
    }),
    createImage1: build.mutation<CreateImage1ApiResponse, CreateImage1ApiArg>({
      query: (queryArg) => ({
        url: `/creative/image/create`,
        method: "POST",
        body: queryArg.creativeImageRequest,
      }),
    }),
    createImage2: build.mutation<CreateImage2ApiResponse, CreateImage2ApiArg>({
      query: (queryArg) => ({
        url: `/creative/campaign/create`,
        method: "POST",
        body: queryArg.campaignImageRequest,
      }),
    }),
    apply1: build.mutation<Apply1ApiResponse, Apply1ApiArg>({
      query: (queryArg) => ({
        url: `/creative/apply`,
        method: "POST",
        body: queryArg.revisionRequest,
        params: {
          updateBrief: queryArg.updateBrief,
          refresh: queryArg.refresh,
        },
      }),
    }),
    getCreativeInspiration: build.query<
      GetCreativeInspirationApiResponse,
      GetCreativeInspirationApiArg
    >({
      query: (queryArg) => ({
        url: `/inspiration/creative`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    downloadCreative: build.query<
      DownloadCreativeApiResponse,
      DownloadCreativeApiArg
    >({
      query: (queryArg) => ({ url: `/file/download/creative/${queryArg.id}` }),
    }),
    themes: build.query<ThemesApiResponse, ThemesApiArg>({
      query: (queryArg) => ({
        url: `/creative/theme`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    trendingThemes: build.query<
      TrendingThemesApiResponse,
      TrendingThemesApiArg
    >({
      query: () => ({ url: `/creative/theme/trending` }),
    }),
    search1: build.query<Search1ApiResponse, Search1ApiArg>({
      query: (queryArg) => ({
        url: `/creative/theme/search`,
        params: {
          return_size: queryArg.returnSize,
          search_term: queryArg.searchTerm,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type CreateTextApiResponse = /** status 200 OK */ CreativeResponse;
export type CreateTextApiArg = {
  creativeTextRequest: CreativeTextRequest;
};
export type CreateImageApiResponse = /** status 200 OK */ CreativeResponse;
export type CreateImageApiArg = {
  productImageRequest: ProductImageRequest;
};
export type ApplyProductUpdatesApiResponse =
  /** status 200 OK */ CreativeRevision[];
export type ApplyProductUpdatesApiArg = {
  productRevisionRequest: ProductRevisionRequest;
};
export type EditImageApiResponse = /** status 200 OK */ CreativeResponse;
export type EditImageApiArg = {
  photoshootEditRequest: PhotoshootEditRequest;
};
export type CreateImage1ApiResponse = /** status 200 OK */ CreativeResponse;
export type CreateImage1ApiArg = {
  creativeImageRequest: CreativeImageRequest;
};
export type CreateImage2ApiResponse = /** status 200 OK */ CreativeResponse;
export type CreateImage2ApiArg = {
  campaignImageRequest: CampaignImageRequest;
};
export type Apply1ApiResponse = /** status 200 OK */ Revision[];
export type Apply1ApiArg = {
  updateBrief?: boolean;
  refresh?: boolean;
  revisionRequest: RevisionRequest;
};
export type GetCreativeInspirationApiResponse =
  /** status 200 OK */ InspirationResponse[];
export type GetCreativeInspirationApiArg = {
  page?: number;
  size?: number;
};
export type DownloadCreativeApiResponse = unknown;
export type DownloadCreativeApiArg = {
  id: string;
};
export type ThemesApiResponse = /** status 200 OK */ CreativeTheme[];
export type ThemesApiArg = {
  page?: number;
  size?: number;
};
export type TrendingThemesApiResponse = /** status 200 OK */ CreativeTheme[];
export type TrendingThemesApiArg = void;
export type Search1ApiResponse = /** status 200 OK */ CreativeTheme[];
export type Search1ApiArg = {
  returnSize?: number;
  searchTerm: string;
};
export type CreativeRevision = {
  revision_id?: string;
  ai_model?: string;
  generated_content?: string;
  generated_url?: string;
  generated_ai_content?: string;
  generated_ai_url?: string;
  template_id?: string;
  texts?: string[];
  color?: string;
  font?: string;
  color_group?: string;
  color_set?: string[];
  background?: string;
};
export type CreativeResponse = {
  content_id?: string;
  revisions?: CreativeRevision[];
};
export type CreativeAiModel = {
  name: string;
  count: number;
};
export type CreativeTextRequest = {
  max_output_length?: number;
  include_emojis?: boolean;
  include_hashtags?: boolean;
  age_group?: string[];
  gender_group?: string;
  education_level?: string;
  income_level?: string;
  type_of_area?: string;
  brand_url?: string;
  topic: string;
  objective: string;
  industry?: string;
  models?: CreativeAiModel[];
  language?: string;
  channel?: string;
  component?: string;
  occupations?: string[];
  family?: string;
  location?: string[];
  interests?: string[];
  loyalty?: string;
};
export type ProductImageRequest = {
  product_file_id?: string;
  product_image?: string;
  referred_file_id?: string;
  referred_image?: string;
  brief?: string;
  file_type?: string;
  file_size?: number;
  brand_url?: string;
  include_texts?: boolean;
  models?: CreativeAiModel[];
  width?: number;
  height?: number;
  ratio?: number;
  objective?: string;
  industry?: string;
  concept?: string;
  color?: string;
  font?: string;
};
export type ProductRevisionRequest = {
  request_id: string;
  content_id: string;
  number_of_revisions?: number;
  revision_parent_id?: string;
  revision_group?: number;
  preserve?: string[];
  product_file_id?: string;
  product_image?: string;
  referred_file_id?: string;
  referred_image?: string;
  brief?: string;
  update_brief: boolean;
  refresh: boolean;
  generate_similar: boolean;
  keep?: string[];
};
export type PhotoshootEditRequest = {
  content_id?: string;
  revision_id?: string;
  product_file_id?: string;
  ai_image?: string;
  texts?: string[];
  color?: string;
  font?: string;
  color_set?: string[];
  font_set?: string[];
  save_revision?: boolean;
};
export type CreativeImageRequest = {
  referred_image?: string;
  file_type?: string;
  file_size?: number;
  age_group?: string[];
  gender_group?: string;
  education_level?: string;
  income_level?: string;
  type_of_area?: string;
  brand_url?: string;
  topic: string;
  objective: string;
  concept?: string[];
  industry?: string;
  models?: CreativeAiModel[];
  width?: number;
  height?: number;
  ratio?: number;
  occupations?: string[];
  family?: string;
  location?: string[];
  interests?: string[];
  loyalty?: string;
};
export type CampaignImageRequest = {
  referred_image?: string;
  file_type?: string;
  file_size?: number;
  brand_url?: string;
  models?: CreativeAiModel[];
  width?: number;
  height?: number;
  ratio?: number;
  objective: string;
  industry?: string;
  concept?: string;
  brief: string;
  color?: string;
  font?: string;
};
export type Id = {
  content_id?: string;
  revision_id?: string;
};
export type Revision = {
  id?: Id;
  type?: string;
  fileId?: string;
  aiImage?: string;
  headerText?: string;
  footerText?: string;
  templateID?: string;
  colorSet?: string;
  fontSet?: string;
  generatedFrom?: string;
  original?: boolean;
  photoshootRevision?: string;
  fontSetIndex?: {
    [key: string]: number;
  };
  colorSetIndex?: {
    [key: string]: number;
  };
  ctatext?: string;
  created_date?: string;
  created_user?: string;
  updated_date?: string;
  updated_user?: string;
  revision_parent_id?: string;
  revision_group?: number;
  generated_content?: string;
  is_favorite?: boolean;
  ai_model?: string;
};
export type RevisionRequest = {
  request_id: string;
  content_id: string;
  number_of_revisions?: number;
  ai_model_names?: string;
  revision_parent_id?: string;
  revision_group?: number;
  preserve?: string[];
};
export type InspirationResponse = {
  type?: "text" | "image" | "creative";
  platform?: string;
  size?: string;
  content?: string;
  fileId?: string;
  revisionId?: string;
  adId?: string;
  subCategory?: string;
};
export type CreativeTheme = {
  id?: string;
  parentConcept?: string;
  childConcept?: string;
  subChildConcept?: string;
  recurrenceRule?: string;
  nextOccurrence?: string;
  imageId?: string;
  thumbnailId?: string;
};
export const {
  useCreateTextMutation,
  useCreateImageMutation,
  useApplyProductUpdatesMutation,
  useEditImageMutation,
  useCreateImage1Mutation,
  useCreateImage2Mutation,
  useApply1Mutation,
  useGetCreativeInspirationQuery,
  useLazyGetCreativeInspirationQuery,
  useDownloadCreativeQuery,
  useLazyDownloadCreativeQuery,
  useThemesQuery,
  useLazyThemesQuery,
  useTrendingThemesQuery,
  useLazyTrendingThemesQuery,
  useSearch1Query,
  useLazySearch1Query,
} = injectedRtkApi;
