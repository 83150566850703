import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewContent } from "../../../store/contentSlice";
import { FunctionComponent, useEffect, useState } from "react";
import { INewContent } from "../../../models/NewContent";
import { AudienceResponse } from "../../../services/AudiencetApi";
import { ContentType } from "../../../constants/ContentType";
import { ContentSize } from "../../../constants/ContentSize";
import ApplyFilters from "../ContentDetails/ApplyFilters";
import SvgTritoneSelectSizeLogo from "../../../assets/IconComponents/TritoneSelectSizeLogo";
import CreativeAddBrief from "./CreativeAddBrief";
import Subject from "../ContentDetails/Subject";
import ProductSelector from "./ProductSelector";

interface CreativeContentProps {
  logoSelectionList: any[]
  productSelectionList: any[]
  onAudienceEdit: (data: AudienceResponse) => void
  onAudienceCreate: () => void;
  recentlyAddedAudience: AudienceResponse[]
}

const CreativeContent: FunctionComponent<CreativeContentProps> = (props: CreativeContentProps) => {
  const dispatch = useAppDispatch();
  const guidelinesState = useAppSelector((k) => k.content.guidelinesState);
  const newContent = useAppSelector((k) => k.content.newContent);
  const [selectedSize, setSelectedSize] = useState<"post" | "landscape" | "story">("post")

  useEffect(() => {
    if (newContent) {
      if (newContent.contentSize == ContentSize.creative_post) {
        setSelectedSize("post")
      }
      else if (newContent.contentSize == ContentSize.creative_landscape) {
        setSelectedSize("landscape");
      }
      else if (newContent.contentSize == ContentSize.creative_story) {
        setSelectedSize("story");
      }
    }
  }, []);


  return (
    <>
      <div className="w-full h-[calc(100vh-135px)] flex-col p-[20px] gap-[12px] inline-flex border-b-1 overflow-scroll scrollbar-hide">
        <div className="w-full h-44 p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <Subject isCreativePages={true} useKeywords={newContent && newContent?.isCreative} />
        </div>
        <div className="w-full p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <CreativeAddBrief />
        </div>

        {newContent?.adId === undefined && false && (
          <div className="w-full h-[223px] p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
            <div className="self-stretch justify-center items-start gap-3 inline-flex">
              <div className="h-7 w-[240px] justify-start items-center gap-2.5 flex">
                <SvgTritoneSelectSizeLogo />
                <div className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">
                  Select Size
                </div>
              </div>
              <div
                className={`flex flex-col justify-center items-center gap-[5px]`}
              >
                <img
                  className={`h-[165px] w-[165px] ${selectedSize === "post"
                    ? `border-1 rounded-lg border-red`
                    : ""
                    }`}
                  src={require("../../../assets/content-creative-ad-post-sample.png")}
                  alt="Creative Post"
                  onClick={() => {
                    setSelectedSize("post");
                    dispatch(
                      setNewContent({
                        ...newContent,
                        contentSize: ContentSize.creative_post,
                        imageHeight: "1080",
                        imageWidth: "1080",
                      } as INewContent)
                    );
                  }}
                />
                <div className="text-neutral-900 text-[13px] font-semibold font-['Inter Tight'] leading-[18.20px]">
                  Post
                </div>
              </div>
              <div
                className={`flex flex-col justify-center items-center gap-[5px] `}
              >
                <img
                  className={`h-[165px] w-[165px] ${selectedSize === "landscape"
                    ? `border-1 rounded-lg border-red`
                    : ""
                    }`}
                  src={require("../../../assets/content-creative-ad-landscape-sample.png")}
                  alt="Creative Landscape"
                  onClick={() => {
                    setSelectedSize("landscape");
                    dispatch(
                      setNewContent({
                        ...newContent,
                        contentSize: ContentSize.creative_landscape,
                        imageHeight: "628",
                        imageWidth: "1200",
                      } as INewContent)
                    );
                  }}
                />
                <div className="text-neutral-900 text-[13px] font-semibold font-['Inter Tight'] leading-[18.20px]">
                  Landscape
                </div>
              </div>
              <div
                className={`flex flex-col justify-center items-center gap-[5px]`}
              >
                <img
                  className={`h-[165px] w-[165px] ${selectedSize === "story"
                    ? `border-1 rounded-lg border-red`
                    : ""
                    }`}
                  src={require("../../../assets/content-creative-ad-story-sample.png")}
                  alt="Creative Story"
                  onClick={() => {
                    setSelectedSize("story");
                    dispatch(
                      setNewContent({
                        ...newContent,
                        contentSize: ContentSize.creative_story,
                        imageHeight: "1920",
                        imageWidth: "1080",
                      } as INewContent)
                    );
                  }}
                />
                <div className="text-neutral-900 text-[13px] font-semibold font-['Inter Tight'] leading-[18.20px]">
                  Story
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="w-full p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <ApplyFilters
            onAudienceEdit={props.onAudienceEdit}
            onAudienceCreate={props.onAudienceCreate}
            recentlyAddedAudience={props.recentlyAddedAudience}
            audiencePopupClassname="w-[520px]"
          />
        </div>
        {newContent?.contentType === ContentType.image && guidelinesState ? (
    <div className="w-full h-[214px] justify-start items-start gap-3 inline-flex">
        <ProductSelector productSelectionList={props.productSelectionList} />
    </div>
) : null}
      </div>
    </>
  );
}

export default CreativeContent;
