import { t } from "i18next";
import { useEffect, useState } from "react";
import PrimaryButton from "../../components/PrimaryButton";
import { Input } from "antd";
import World from "../../assets/IconComponents/World";
export interface IOnboardingWebsiteProps {
  onContinue: (string) => void;
}

function OnboardingWebsite(props: IOnboardingWebsiteProps) {
  const [websiteURL, setWebsiteURL] = useState<string>("");
  const [error, setError] = useState("");
  function checkURLValid(str) {
    var urlRegex =
      "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
    var url = new RegExp(urlRegex, "i");
    return str.length < 2083 && url.test(str);
  }
  return (
    <div className="relavite min-w-full h-full flex flex-col justify-between">
      <div className="w-full  flex flex-col items-center">
        <div className="flex flex-col items-center">
          <span className="text-H2 font-medium text-black mt-[60px]">
            {t("your-website")}
          </span>
          <span className="text-BodyMedium text-black mt-[20px]">
            {t(
              "this-helps-evercopy-to-capture-your-brand-voice-and-product-nuances-setting-the-stage-for-crafting-targeted-ads"
            )}
          </span>

          <div className="flex flex-col w-full ">
            <div className="flex mt-3">
              <Input size="large" placeholder={"https://yourwebsite.io"} prefix={<World width={16} height={16} fill="" />}
                className={`mb-2 bg-inputBg ${error != "" ? "border-red" : ""}`}
                onChange={(e) => {
                  setWebsiteURL(e.target.value);
                }}
                onBlur={(e) => {
                  if (!checkURLValid(e.target.value)) {
                    setError(t("url-not-valid") ?? "");
                    setTimeout(() => {
                      setError("");
                    }, 2000);
                  }
                }}
              />

              <PrimaryButton
                title={t("import")}
                isDisabled={websiteURL === ""}
                onClick={() => {
                  if (checkURLValid(websiteURL)) {
                    props.onContinue(websiteURL);
                  } else {
                    setError(t("url-not-valid") ?? "");
                    setTimeout(() => {
                      setError("");
                    }, 2000);
                  }
                }}
                className="ml-2"
              />
            </div>

            {error != "" && <span className="text-red text-BodyMedium mt-1">{error}</span>}
          </div>
        </div>
      </div>
      <div className="absolute justify-end flex flex-row right-0 bottom-0 bg-white w-full h-[50px] py-1">
        <PrimaryButton
          noFill
          className="w-16 h-11 mr-2"
          title={t("skip")}
          onClick={() => {
            props.onContinue("");
          }}
        />
      </div>
    </div>
  );
}

export default OnboardingWebsite;
