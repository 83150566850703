import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationPopupOpener from "../../components/ConfirmationPopup/ConfirmationPopup";
import ToggleButton from "../../components/ToggleButton";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { Plan, useLazyListPQuery as usePlanQuery } from "../../services/PlanApi";
import { toggleDialog, toggleLoading, toggleMenu } from "../../store/appSlice";
import BenefitsTable from "./BenefitTable/BenefitsTable";
import PlanCard from "./PlanCard";
import SubscriptionPopupOpener from "./SubscriptionPopup/SubscriptionPopup";
import BenefitsTableMobile from "./BenefitTable/BenefitsTableMobile";
import PlanSelectionPopupOpener from "./PlanSelectionPopup";
import { useSubscribeMutation } from "../../services/PaymentApi";

export type SubscribePlanParameters = {
    plan: Plan;
    skipTrial: boolean;
    applyDiscount: boolean;
};

const PlanPage = () => {
    const [plans, setPlans] = useState<Plan[]>([]);
    const [selectedPlanType, setSelectedPlanType] = useState<"monthly" | "yearly">("monthly");
    const [subscribePlanParameters, setSubscribePlanParameters] = useState<SubscribePlanParameters>();
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const [showPlanSelectionPopup, setShowPlanSelectionPopup] = useState(false);
    const [showSubscriptionSuccessPopup, setShowSubscriptionSuccessPopup] = useState(false);
    const [listPlans, { data: planData }] = usePlanQuery();
    const [trialEndDate, setTrialEndDate] = useState<Date>();
    const dispatch = useAppDispatch();
    const trialedBefore = useAppSelector(state => state.userSettings.trialed_before);
    const benefitTableRef = useRef<HTMLDivElement>(null);
    const userPaymentMethod = useAppSelector(state => state.userSettings.payment_method);
    const navigate = useNavigate();
    const [subscribe, { data: subscriptionResult, isLoading: subscribing, error: subscribeError }] = useSubscribeMutation();

    useEffect(() => {
        listPlans();
    }, []);

    useEffect(() => {
        dispatch(toggleLoading(subscribing));
    }, [subscribing]);

    useEffect(() => {
        if (planData) {
            console.log(`Setting plans`);
            setPlans(planData);
            setShowPlanSelectionPopup(true);
        }
    }, [planData]);

    useEffect(() => {
        if (subscriptionResult) {
            onSubscriptionCompleted(moment().add(30, "days").toDate());
        }

    }, [subscriptionResult]);

    function onSubscriptionCompleted(trialEndDateParam: Date) {
        setTrialEndDate(trialEndDateParam);
        setShowSubscriptionPopup(false);
        dispatch(toggleDialog(false));
        setShowSubscriptionSuccessPopup(true);
        dispatch(toggleMenu(true));

        // Determine the value based on the selected plan type
        let value;
        if (selectedPlanType === "yearly") {
            value = (subscribePlanParameters?.plan ?? plans[0]).yearlyPrice;
        } else {
            value = (subscribePlanParameters?.plan ?? plans[0]).monthlyPrice;
        }
        // Trigger Google Analytics conversion event
        if (window.gtag) {
            window.gtag('event', 'conversion', {
                'send_to': 'AW-11309297361/dCt9CIWrvYUZENHd2JAq',
                'value': value,
                'currency': 'USD'
            });
        }
    }


    return (
        <div className=" flex h-[calc(100vh-10px)] md:h-[calc(100vh-10px)] overflow-scroll scrollbar-hide">
            <div className="flex flex-col mt-10 mb-[80px] ml-[80px] mr-[80px] gap-[80px]">
                <div className="w-full flex-col justify-start items-start gap-10 inline-flex relative ">
                    <div className="self-stretch h-[180px] flex-col justify-center items-center gap-[30px] flex">
                        <div className="self-stretch h-20 flex-col justify-center items-center gap-5 flex">
                            <div className="h3 text-black">
                                Select Plan
                            </div>
                            <div className="body-big text-black">
                                Let’s setup your account and get you started!
                            </div>
                        </div>
                        <div className="relative justify-center items-center gap-2.5 inline-flex mr-3">
                            <div className="text-center text-black text-base font-semibold font-['Inter Tight'] leading-snug">Monthly</div>
                            <ToggleButton
                                isChecked={selectedPlanType === "yearly"}
                                handleCheckBoxChange={function (yearly: any) {
                                    yearly ? setSelectedPlanType("yearly") : setSelectedPlanType("monthly");
                                }}
                                checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                                checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                                uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                                uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition transition"
                            />
                            <div className="text-center text-black text-base font-semibold font-['Inter Tight'] leading-snug">Yearly</div>
                            <div className="absolute right-[-90px] text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                                2 MONTHS FREE
                            </div>
                        </div>
                        <div className="flex flex-row gap-[30px]">
                            <div className="self-stretch text-center text-gray text-[13px] font-normal font-['Inter Tight'] underline leading-[18.20px] mr-3">
                                <a href="#BENEFITS_TABLE">See full list of features</a>
                            </div>
                            {!trialedBefore &&
                                (<div className="self-stretch text-center">
                                    <div className="text-red text-[13px] font-normal font-['Inter Tight'] underline leading-[18.20px] cursor-pointer" onClick={
                                        () => {
                                            console.log(`going fast`)
                                            subscribe({
                                                subscribeRequest: {
                                                    plan_id: plans.at(0)?.id ?? "",
                                                    subscription_type: "MONTHLY",
                                                    skip_trial: false,
                                                    apply_discount: false,
                                                    promotion_code: undefined,
                                                },
                                            });

                                        }}>Continue With 30-Day Free Trial</div>
                                </div>)
                            }
                        </div>
                    </div>
                    <div className="w-screen md:hidden">
                        <BenefitsTableMobile plans={plans} selectedPlanType={selectedPlanType} />
                    </div>
                    <div className="hidden md:inline-flex justify-start items-start gap-2.5 mt-5">
                        {plans.map(p => (
                            <PlanCard
                                plan={p}
                                onTrialClicked={() => {
                                    subscribe({
                                        subscribeRequest: {
                                            plan_id: plans.at(0)?.id ?? "",
                                            subscription_type: "MONTHLY",
                                            skip_trial: false,
                                            apply_discount: false,
                                            promotion_code: undefined,
                                        },
                                    });
                                }}
                                planType={selectedPlanType}
                                onSubscribe={function (plan: Plan, skipTrial: boolean, applyDiscount: boolean): void {
                                    setSubscribePlanParameters({ plan, skipTrial, applyDiscount });
                                    setShowSubscriptionPopup(true);
                                }}
                                showSkipTrial={true}
                                isUpdate={false}
                                trialedBefore={trialedBefore}
                            />
                        ))}
                    </div>
                </div>
                <div id="BENEFITS_TABLE" className="hidden md:block">
                    <BenefitsTable ref={benefitTableRef} plans={plans} />
                </div>


                {showSubscriptionPopup &&
                    (
                        <SubscriptionPopupOpener
                            mode={"subscription"}
                            plan={subscribePlanParameters?.plan ?? plans[0]}
                            selectedPlanType={selectedPlanType}
                            onClose={function (): void {
                                setShowSubscriptionPopup(false);
                                dispatch(toggleDialog(false));
                            }}
                            onSubscriptionCompleted={(trialEndDateParam) => {
                                onSubscriptionCompleted(trialEndDateParam);
                            }}
                            onPlanUpdated={(effectiveAt?: string) => { }}
                            onUpdatePaymentMethod={() => { }}
                            skipTrial={subscribePlanParameters?.skipTrial ?? false}
                            applyDiscount={subscribePlanParameters?.applyDiscount ?? false}
                            paymentMethod={userPaymentMethod}
                        />
                    )}
                {showSubscriptionSuccessPopup &&
                    (
                        <ConfirmationPopupOpener
                            heading={!subscribePlanParameters?.skipTrial ? "Congrats, enjoy your Free Trial!" :
                                "Your plan has been updated successfully!"}
                            subHeading={!subscribePlanParameters?.skipTrial
                                ? `Your current plan includes 250 credits for one month, granting full access to all features. Feel free to upgrade your plan at any time.`
                                : "Your plan has been successfully updated. Adjusted features are now in effect."}
                            buttonText={"Continue"}
                            onClose={() => {
                                setShowSubscriptionSuccessPopup(false);
                                dispatch(toggleDialog(false));
                                navigate("/app/homepage");
                            }}
                        />
                    )}

                {
                    showPlanSelectionPopup && (<PlanSelectionPopupOpener
                        onTrialClicked={function (): void {
                            subscribe({
                                subscribeRequest: {
                                    plan_id: plans.at(0)?.id ?? "",
                                    subscription_type: "MONTHLY",
                                    skip_trial: false,
                                    apply_discount: false,
                                    promotion_code: undefined,
                                },
                            });
                        }}
                        onPlanSelectionClicked={function (): void {
                            setShowPlanSelectionPopup(false);
                            dispatch(toggleDialog(false));
                        }} />)
                }
            </div>
        </div>
    );
};

export default PlanPage;


