import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { ContentRequest, useSave4Mutation } from "../../../services/ContentApi";
import { useEffect } from "react";
import { ContentType } from "../../../constants/ContentType";
import { toggleContentWizard } from "../../../store/appSlice";
import { setNewContent } from "../../../store/contentSlice";
import { TonOfVoices } from "../../../constants/TonOfVoices";
import SvgGenerateSimilarIcon from "../../../assets/IconComponents/GenerateSimilarIcon";
import SvgGenerateSimilarWhiteIcon from "../../../assets/IconComponents/GenerateSimilarWhiteBg";

function ControlButtons(props) {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const newContent = useAppSelector((k) => k.content.newContent);


    const [
        createContent,
        {
            isLoading: isContentCreationLoading,
            isSuccess: contentCreationSuccess,
            data: contentCreationData,
            error: createContentError
        },
    ] = useSave4Mutation();


    useEffect(() => {
        if (contentCreationSuccess && !props.isInSide) {
            dispatch(toggleContentWizard());
            navigate(
                "/app/loopdetails/" + contentCreationData?.id?.content_id ?? "",
                { state: { newLoopCreated: true } }
            );
        } else if (props.setContentCreationDataID) {
            props.setContentCreationDataID(contentCreationData?.id?.content_id ?? "")
        }
    }, [contentCreationSuccess]);

    const getTonOfVoices = () => {
        const values = newContent?.tonOfVoice?.map((k) => k.value);
        const filteredVoices = TonOfVoices.filter(
            (k) => values?.includes(k.value)!
        );

        return filteredVoices.map((k) => k.key).join(" & ");
    };


    const getFilters = () => {
        var payload = {
            age_group: newContent?.ageGroup?.map((k) => k.title).join(" & ") ?? "",
            bot_type: "marketing",
            gender_group:
                newContent?.genderGroup?.map((k) => k.title).join(" & ") ?? "",
            education_level:
                newContent?.education?.map((k) => k.title).join(" & ") ?? "",
            objective:
                newContent?.contentObjective?.map((k) => k.title).join(" & ") ?? "",
            tone_of_voice: getTonOfVoices(),
            message: newContent?.contentAbout ?? "",
            audience_id: newContent?.audienceId,
            creativity: newContent?.creativity,
            reference_files: newContent?.reference_files,
        };
        if (newContent?.contentType === ContentType.image) {
            payload["purpose"] = "Background";
            payload["bot_type"] = "image-gen-prompt";
        }

        return payload;
    };

    const createLoop = () => {
        if (newContent?.medium?.name != "Custom") {
            var payload = {
                name: newContent?.loopName ?? "",
                type: ContentType[newContent?.contentType ?? ContentType.text],
                platform: `${newContent?.medium?.name} | ${newContent?.mediumOption?.title}`,
                filters: getFilters(),
                brand_id: newContent?.brand?.id,
                cta: newContent?.cta,
                logo_file_id:
                    newContent?.logoFileId !== undefined && newContent?.logoFileId !== ""
                        ? newContent.logoFileId
                        : undefined,
                product_file_id:
                    newContent?.productFileId !== undefined &&
                        newContent?.productFileId !== ""
                        ? newContent.productFileId
                        : undefined,
                ad_id: newContent?.adId
            } as ContentRequest;

            createContent({
                contentRequest: payload,
            });
        } else {
            var payload = {
                name: newContent?.loopName ?? "",
                type: ContentType[newContent?.contentType ?? ContentType.text],
                platform: `${newContent?.medium?.name}`,
                image_width: newContent?.imageWidth,
                image_height: newContent?.imageHeight,
                logo_file_id:
                    newContent?.logoFileId !== undefined && newContent?.logoFileId !== ""
                        ? newContent.logoFileId
                        : undefined,
                product_file_id:
                    newContent?.productFileId !== undefined &&
                        newContent?.productFileId !== ""
                        ? newContent.productFileId
                        : undefined,
                filters: getFilters(),
                brand_id: newContent?.brand?.id,
                cta: newContent?.cta,
                ad_id: newContent?.adId,
                creative_topic: newContent?.contentAbout ?? "",
                creative_image_id: newContent.creativeImageId ?? "",
                creative_theme: newContent.creative_theme ?? "",
            } as ContentRequest;

            if (newContent?.contentType === ContentType.text) {
                if (newContent?.mediumOption?.description == "Words") {
                    payload.words_limit = Number(newContent?.mediumOption?.value) ?? 100
                } else if (newContent?.mediumOption?.description == "Sentences") {
                    payload.sentences_limit = Number(newContent?.mediumOption?.value) ?? 100
                }
            }

            createContent({
                contentRequest: payload,
            });
        }
    };

    return <>
        <div className={`w-full h-[85px] py-[15px] ${props.isInSide ? '' : 'shadow bg-white'} justify-between items-center flex shrink-0`}>
            <div className="w-[52px] self-stretch px-2.5 py-2 opacity-0 bg-white rounded-[5px] border border-stone-200 justify-center items-center gap-2.5 flex">
                <div className="text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">Back</div>
            </div>
            <div className={`self-stretch ${props.isInSide ? 'justify-end' : 'justify-start'} items-center gap-2.5 flex`}>
                {!props.isInSide && <PrimaryButton
                    title={"Cancel"}
                    noFill
                    className="mr-2"
                    onClick={function (): void {
                        dispatch(setNewContent(undefined));
                        dispatch(toggleContentWizard());
                    }}
                />}
                <PrimaryButton
                    isDisabled={(newContent?.contentAbout ?? "") === "" || (props.isInSide && newContent?.isCreative && !(newContent?.adId || newContent?.creativeImageId || newContent?.creative_theme) )}
                    icon={props.isInSide ? <SvgGenerateSimilarWhiteIcon /> : undefined}
                    title={props.isInSide ? "Generate" : "Get Started"}
                    className={'mr-2'}
                    onClick={function (): void {
                        createLoop();
                    }}
                />
               
            </div>
        </div>
    </>


}

export default ControlButtons;
