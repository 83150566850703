import { FunctionComponent, useEffect, useState } from "react";
import ToggleButton from "../../../components/ToggleButton";
import UploadFileInfinite from "../../../components/UploadFile/UploadFileInfinite";
import SvgBrandHubBrandLogosIcon from "../../../assets/IconComponents/BrandHubBrandLogosIcon";
import { FileProps, useLazyFindById4Query } from "../../../services/BrandApi";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import SvgBrandHubProductPhotosIcon from "../../../assets/IconComponents/BrandHubProductPhotosIcon";
import { setNewContent } from "../../../store/contentSlice";
import { ContentWizardView } from "../../../models/ContentWizardView";
import { INewContent } from "../../../models/NewContent";
import ProductSelectorIcon from "../../../assets/IconComponents/ProductSelectorIcon";
import { Button } from "antd";


interface ProductSelectorProps {
  className?: string
  headerClassName?: string
  contentClassName?: string
  productSelectionList: FileProps[]
  briefContent?: INewContent
  onSelectionChanged?: (data: FileProps[]) => void
  onListingChanged?: (data: FileProps[]) => void
  callingParent?: string
  bgRemoved?: (string) => void;
}


const ProductSelector: FunctionComponent<ProductSelectorProps> = (props: ProductSelectorProps) => {
  const dispatch = useAppDispatch();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const newContent = useAppSelector((k) => k.content.newContent);
  const [selectionList, setSelectionList] = useState<FileProps[]>([])


  useEffect(() => {
    if (props.briefContent) {
      setCollapsed(false)
    }
  }, [props.briefContent])

  useEffect(() => {
    if (newContent?.productFileId) {
      setCollapsed(false);
    }
  }, [newContent])

  useEffect(() => {
    if (props.productSelectionList.length !== 0) {
      setSelectionList(props.productSelectionList);
      if ((props.callingParent ?? "") === "productphotoshoot") {
        selectImage();
      }
    }
  }, [props.productSelectionList])

  useEffect(() => {
    if (selectionList.length > 0 && newContent?.productFileId && selectionList.filter(k => k.id === newContent.productFileId).length === 0) {
      dispatch(
        setNewContent({
          ...newContent,
          productFileId: selectionList[0].id
        })
      );
    }
  }, [selectionList])

  function selectImage() {
    if (!newContent?.productFileId) {
      if (props.productSelectionList.length > 0) {
        dispatch(
          setNewContent({
            ...newContent,
            productFileId: props.productSelectionList[0].id
          })
        );
      }
    } else {
      if (props.productSelectionList.length > 0) {
        if (props.productSelectionList.filter(k => k.id === newContent.productFileId).length === 0) {
          dispatch(
            setNewContent({
              ...newContent,
              productFileId: props.productSelectionList[0].id
            })
          );
        }
      }
    }
  }

  function getSelectedFileIds() {
    if (props.briefContent) {
      return props.briefContent?.productFileId !== undefined ? [props.briefContent.productFileId] : []
    } else {
      return newContent?.productFileId ? [newContent.productFileId] : []
    }
  }

  return (
    <>
      <div
        className={`w-[394px] p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-4 inline-flex ${props.className}`}
      >
        <div
          className={`w-[366px] justify-between items-center inline-flex ${props.headerClassName}`}
        >
          <div className="justify-start items-center gap-2.5 flex">
            <ProductSelectorIcon
              fill={
                collapsed &&
                  (props.callingParent ?? "") !== "productphotoshoot"
                  ? "#8E8E8E"
                  : "#FF5555"
              }
              className="flex shrink-0"
            />
            <text className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              {props.briefContent &&
                (props.callingParent ?? "") !== "productphotoshoot"
                ? "Select Product Photo"
                : "Add Product Photo"}
            </text>
          </div>

          {props.briefContent === undefined &&
            (props.callingParent ?? "") !== "productphotoshoot" && (
              <ToggleButton
                isChecked={newContent?.productFileId !== undefined}
                handleCheckBoxChange={function (checked: boolean): void {
                  setCollapsed(!checked);
                  if (checked) {
                    selectImage();
                  } else {
                    dispatch(
                      setNewContent({
                        ...newContent,
                        productFileId: undefined,
                      })
                    );
                  }
                }}
                checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
                uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
              />
            )}
          {props.briefContent && (
            <div
              onClick={() => {
                dispatch(
                  setNewContent({
                    ...newContent,
                    wizardView: ContentWizardView.logoLibrary,
                  })
                );
              }}
              className="flex items-center self-stretch text-center text-red text-[13px] font-normal font-['Inter Tight'] underline leading-none cursor-pointer"
            ></div>
          )}
        </div>

        {(!collapsed ||
          (props.callingParent ?? "") === "productphotoshoot") && (
            <div
              className={`self-stretch h-[142px] flex-col justify-start items-start gap-2.5 flex ${props.contentClassName}`}
            >
              {((props.briefContent &&
                props.briefContent.productFileId &&
                selectionList.length > 0) ||
                (props.briefContent && !props.briefContent.productFileId) ||
                !props.briefContent) && (
                  <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                    <UploadFileInfinite
                      size={props.briefContent ? "brief" : "small"}
                      maxFileCount={10}
                      automaticallySelectUploadedFile={true}
                      uploadedFiles={selectionList.slice(0, 2) ?? []}
                      title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                      description="Upload .jpg or .png files. Max 25mb."
                      filesChanged={(f: any[]) => {
                        if (f && f.length > 0) {
                          const updatedFileSet = [
                            ...f.slice(0, 2).map((k) => k.uploadedId),
                          ];
                          const previousFileSet = [
                            ...selectionList.map((m) => m.id),
                          ];
                          const difference = updatedFileSet.filter(
                            (k) => !previousFileSet.includes(k)
                          );

                          setSelectionList([
                            ...f.slice(0, 2).map((k) => {
                              return { id: k.uploadedId };
                            }),
                          ]);
                          if (props.onListingChanged) {
                            props.onListingChanged([
                              ...f.slice(0, 2).map((k) => {
                                return { id: k.uploadedId };
                              }),
                            ]);
                          }

                          if (difference.length > 0) {
                            dispatch(
                              setNewContent({
                                ...newContent,
                                productFileId: difference.at(0),
                              })
                            );
                          }
                        }
                      }}
                      selectionChanged={(pickedFiles) => {
                        if (props.briefContent === undefined) {
                          if (
                            newContent?.productFileId === undefined &&
                            pickedFiles.length === 0
                          ) {
                            return;
                          }

                          const newProductFileId =
                            pickedFiles.length > 0
                              ? pickedFiles.at(0)?.id
                              : undefined;

                          if (newProductFileId != newContent?.productFileId) {
                            dispatch(
                              setNewContent({
                                ...newContent,
                                productFileId: newProductFileId,
                              })
                            );
                          }
                        } else {
                          if (props.onSelectionChanged) {
                            props.onSelectionChanged(pickedFiles);
                          }
                        }
                      }}
                      selectionEnabled={true}
                      maxSelectionSize={1}
                      selectedFileIds={getSelectedFileIds()}
                      maxThumbnailCount={2}
                      addNewFileToFront={true}
                      backgroundRemoved={
                        (props.callingParent ?? "") === "productphotoshoot"
                          ? (str) => {
                            if (props.bgRemoved) {
                              props.bgRemoved(str);
                            }
                          }
                          : undefined
                      }
                    />
                  </div>
                )}
              {props.briefContent === undefined && (
                <div
                  onClick={() => {
                    if ((props.callingParent ?? "") === "productphotoshoot") {
                      dispatch(
                        setNewContent({
                          ...newContent,
                          wizardView:
                            ContentWizardView.aiProductPhotoshootImageLibrary,
                        })
                      );
                    } else {
                      dispatch(
                        setNewContent({
                          ...newContent,
                          wizardView: ContentWizardView.productLibrary,
                        })
                      );
                    }
                  }}
                  className="self-stretch text-center text-red text-[13px] font-normal font-['Inter Tight'] underline leading-none cursor-pointer"
                >
                  <a>View Library</a>
                </div>
              )}
            </div>
          )}
      </div>
    </>
  );
}

export default ProductSelector;
