import { FunctionComponent, useEffect, useState } from "react";
import ToggleButton from "../../../components/ToggleButton";
import UploadFileInfinite from "../../../components/UploadFile/UploadFileInfinite";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewContent } from "../../../store/contentSlice";
import { ContentWizardView } from "../../../models/ContentWizardView";
import { FileProps } from "../../../services/BrandApi";
import LogoSelectorIcon from "../../../assets/IconComponents/LogoSelectorIcon";
import SvgQuestionMark from "../../../assets/IconComponents/QuestionMark";
import { Button } from "antd";


interface ProductSelectorProps {
    productSelectionList: FileProps[]
}

const ProductSelector: FunctionComponent<ProductSelectorProps> = (
    props: ProductSelectorProps
) => {
    const dispatch = useAppDispatch();
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const newContent = useAppSelector((k) => k.content.newContent);
    const [selectionList, setSelectionList] = useState<FileProps[]>([])
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([])

    useEffect(() => {
        if (newContent?.creative_referred_image_id) {
            setCollapsed(false);
        }
    }, [newContent])

    useEffect(() => {
        if (selectionList.length === 0) {
            setSelectionList(props.productSelectionList)
        }
    }, [props.productSelectionList])


    useEffect(() => {
        if (selectionList.length > 0 && newContent?.creative_referred_image_id && selectionList.filter(k => k.id === newContent.creative_referred_image_id).length === 0) {
            dispatch(
                setNewContent({
                    ...newContent,
                    creative_referred_image_id: selectionList[0].id
                })
            );
        }
    }, [selectionList])

    function selectImage() {
        if (!newContent?.creative_referred_image_id) {
            if (props.productSelectionList.length > 0) {
                dispatch(
                    setNewContent({
                        ...newContent,
                        creative_referred_image_id: props.productSelectionList[0].id
                    })
                );
            }
        } else {
            if (props.productSelectionList.length > 0) {
                if (props.productSelectionList.filter(k => k.id === newContent.creative_referred_image_id).length === 0) {
                    dispatch(
                        setNewContent({
                            ...newContent,
                            creative_referred_image_id: props.productSelectionList[0].id
                        })
                    );
                }
            }
        }
    }

    useEffect(() => {
        setSelectedFileIds(newContent?.creative_referred_image_id !== undefined ? [newContent.creative_referred_image_id] : [])
    }, [newContent])

    return <>
        <div className="w-full p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-4 inline-flex">
            <div className="w-full justify-between items-center inline-flex">
                <div className="flex justify-end items-center gap-2.5">
                    <LogoSelectorIcon fill={collapsed ? "#8E8E8E" : "#FF5555"} className="flex shrink-0" />
                    <text className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                        {"Add Visual"}
                    </text>
                    <div className="flex items-center">
                        <div className="mr-1" data-tooltip-id={"app-tooltip"} data-tooltip-html={"Optionally add an image (e.g., product, place) for your ad here."}>
                            <SvgQuestionMark />
                        </div>
                    </div>
                </div>

                <ToggleButton isChecked={newContent?.creative_referred_image_id !== undefined}
                    handleCheckBoxChange={function (checked: boolean): void {
                        setCollapsed(!checked);
                        if (checked) {
                            selectImage();
                        } else {
                            dispatch(setNewContent({
                                ...newContent,
                                creative_referred_image_id: undefined,
                            }))
                        }
                    }}
                    checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                    checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                    uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
                    uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
                />
            </div>

            {!collapsed && (<div className="self-stretch flex-col justify-start items-start gap-2.5 flex">
                <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                    <UploadFileInfinite
                        size="creative_small"
                        maxFileCount={10}
                        acceptedFileTypes={{
                            "image/jpeg": [".jpeg", ".jpg"],
                            "image/png": [".png"],
                        }}
                        uploadedFiles={selectionList.slice(0, 4) ?? []}
                        title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                        description="Upload .jpg or .png files. Max 25mb."
                        filesChanged={(f: any[]) => {
                            if (f && f.length > 0) {
                                const updatedFileSet = [...f.slice(0, 4).map(k => k.uploadedId)];
                                const previousFileSet = [...selectionList.map(m => m.id)];
                                const difference = updatedFileSet.filter(k => !previousFileSet.includes(k))
                                setSelectionList([...f.slice(0, 4).map(k => { return { id: k.uploadedId } })])

                                if (difference.length > 0) {
                                    dispatch(
                                        setNewContent({
                                            ...newContent,
                                            creative_referred_image_id: difference.at(0)
                                        })
                                    );
                                }
                            }
                        }}
                        selectionChanged={(pickedFiles) => {
                            if (newContent?.creative_referred_image_id === undefined && pickedFiles.length === 0) {
                                return;
                            }
                            console.log(`picked file ids => ${JSON.stringify(pickedFiles)}`)
                            dispatch(
                                setNewContent({
                                    ...newContent,
                                    creative_referred_image_id: pickedFiles.length > 0 ? pickedFiles.at(0)?.id : undefined,
                                })
                            );
                        }}
                        selectionEnabled={true}
                        maxSelectionSize={1}
                        maxThumbnailCount={4}
                        selectedFileIds={selectedFileIds}
                        addNewFileToFront={true}
                    />
                </div>
                <Button
                    type="text"
                    size="small"
                    onClick={() => {
                        dispatch(setNewContent({
                            ...newContent,
                            wizardView: ContentWizardView.creativeReferredImageLibrary
                        }));
                    }}
                    className="text-red hover:text-red self-center underline"
                >
                    View Library
                </Button>
            </div>
            )}
        </div>
    </>
}

export default ProductSelector;
