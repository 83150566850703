import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import {
  setActiveItem,
  setActivePage,
  setDialog,
  setMenuCollapsed,
  toggleDialog,
  toggleLoading,
  toggleBrandEditing,
  setCollapsedModeEnabled,
  toggleNotificationsSidebar
} from "../../store/appSlice";
import {
  setAccessToken,
  setLastSelectedBrandId,
  setRefreshToken,
  toggleLoggedIn,
} from "../../store/persistedSlice";
import PrimaryButton from "../PrimaryButton";
import { ButtonState } from "./LeftMenuButton";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShimmerTitle } from "react-shimmer-effects";
import {
  Logout,
  Loops,
} from "../../assets/IconComponents";
import { Mediums } from "../../constants/Mediums";
import { useLazyLogoutQuery } from "../../services/AuthenticationApi.old/AuthenticationApi";
import { updateMenu } from "../../store/appSlice";
import { setNewContent } from "../../store/contentSlice";
import SvgLogoIcon from "../../assets/IconComponents/LogoIcon";
import { Id, useLazyList5Query } from "../../services/ContentApi";
import { useWindowSize } from "@uidotdev/usehooks";
import SvgCollapse from "../../assets/IconComponents/SvgCollapse";
import SvgCollapseReverse from "../../assets/IconComponents/SvgCollapseReverseIcon";
import SvgSettings from "../../assets/IconComponents/Settings";
import SvgMarketPlannerIcon from "../../assets/IconComponents/MarketPlannerIcon";
import SvgAIContentManagerIcon from "../../assets/IconComponents/AIContentManagerIcon";
import MenuBrandSelector from "./MenuBrandSelector";
import SvgSidebarNotificationsIcon from "../../assets/IconComponents/SidebarNotificationsIcon";
import { NotificationResponse, useLazyGetNotificationsQuery } from "../../services/NotificationsManager";
import AIMarketingToolsSubMenu from "./AIMarketingToolsSubMenu";
import SidebarMenuButton from "./SidebarMenuButton";
import SvgHome from "../../assets/IconComponents/Home";
import SvgCreativeAdGenerator from "../../assets/IconComponents/SvgCreativeAdGeneratorIcon";
import CreditLeftCard from "./CreditLeftCard";

function SidebarAlternative() {
  const [
    logoutUser,
    {
      isLoading: isLogoutLoading,
      isSuccess: isLogoutSuccess,
      isError: isLogoutError,
    },
  ] = useLazyLogoutQuery();
  const updateMenuEvent = useAppSelector((state) => state.app.updateMenu);
  const collapsedModeEnabled = useAppSelector(
    (state) => state.app.collapsedModeEnabled
  );
  const isBrandEditing = useAppSelector((state) => state.app.brandEditing);
  const activePage = useAppSelector((state) => state.app.activePage);
  const isNotificationSidebarActive = useAppSelector((state) => state.app.notificationsSidebarActive);
  const activeItem = useAppSelector((state) => state.app.activeItem);
  const [getContentList, { isLoading: contenListLoading, data: contentList }] = useLazyList5Query();
  const dispatch = useAppDispatch();
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const [
    getNotifications,
    { data: notificationModels },
  ] = useLazyGetNotificationsQuery();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state) => state.persisted.isLoggedIn);
  const showMenu = useAppSelector((state) => state.app.showMenu);
  const isExpanded = useAppSelector((state) => state.app.isMenuCollapsed);
  const [logoutClosed, setLogoutClosed] = useState(true);
  const size = useWindowSize();
  const [isBrandPopoverOpen, setIsBrandPopoverOpen] = useState<boolean>(false);
  const [localActiveItem, setLocalActiveItem] = useState("");
  const newContent = useAppSelector((k) => k.content.newContent);
  const [unfilteredNotificationModels, setUnfilteredNotificationModels] =
    useState<NotificationResponse[]>([]);


  let container: HTMLDivElement;


  const prepareMenu = () => {
    getContentList({
      page: 0,
      size: 999,
    });
  };
  useEffect(() => {
    if (isLoggedIn) prepareMenu();
    dispatch(setCollapsedModeEnabled(true));
    // Setup interval to fetch data every 5 seconds
    const intervalId = setInterval(() => {
      if (lastSelectedBrandId) {
        getNotifications({
          size: 200,
          page: 0,
          brandId: [lastSelectedBrandId],
        });
      }
    }, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [isLoggedIn]);

  useEffect(() => {
    if (notificationModels) {
      setUnfilteredNotificationModels(notificationModels);
    }
  }, [notificationModels]);


  useEffect(() => {
    if (activeItem) {
      console.log("Active item:", activeItem);
      setLocalActiveItem(activeItem ?? "");
    }
  }, [activeItem]);

  useEffect(() => {
    prepareMenu();
  }, [updateMenuEvent]);
  useEffect(() => {
    dispatch(updateMenu());
  }, [contentList]);

  const showLoop = (id?: Id) => {
    navigate(`loopdetails/${id?.content_id}`);

    // navigate(`revisionhisory`, { state: { content_id: id.content_id } })
  };


  const cleanUp = () => {
    dispatch(setLastSelectedBrandId(undefined));
    dispatch(setNewContent(undefined));
  };

  const logout = () => {
    dispatch(
      setDialog({
        actions: (
          <>
            <PrimaryButton
              noFill
              title={t("cancel")}
              onClick={() => {
                dispatch(toggleDialog(false));
                setLogoutClosed(true);
              }}
            />
            <PrimaryButton
              className="ml-2"
              title={t("logOut")}
              onClick={() => {
                cleanUp();
                dispatch(toggleDialog(false));

                dispatch(setActivePage(""));
                dispatch(setActiveItem(""));
                setLogoutClosed(true);
                logoutUser();
              }}
            />
          </>
        ),
        title: t("log-out"),
        message: t(
          "are-you-sure-you-would-like-to-log-out-of-your-evercopy-account"
        ),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );
    dispatch(toggleDialog(true));
  };

  // useEffect(() => {
  //     clickOutside(container, () => {
  //
  //     })
  // }, [])

  useEffect(() => {
    if (isLogoutSuccess || isLogoutError) {
      dispatch(setAccessToken(""));
      dispatch(setRefreshToken(""));
      dispatch(toggleLoggedIn(false));
    }
  }, [isLogoutSuccess, isLogoutError]);

  useEffect(() => {
    dispatch(toggleLoading(isLogoutLoading));
  }, [isLogoutLoading]);

  const getMediaIcon = (mediaName: string) => {
    const platformName = mediaName.split(" | ")[0];
    const icon = Mediums.find((k) => k.name === platformName)?.icon;
    return icon;
  };

  const isMobile = () => {
    return size.width && size.width <= 500;
  };
  const exitWizard = (loopId: Id | undefined, clickType: number) => {
    dispatch(
      setDialog({
        message:
          t(
            "are-you-sure-you-want-to-leave-this-page-unsaved-changes-will-be-lost"
          ) ?? "",
        title: t("unsaved-changes"),
        actions: (
          <>
            <PrimaryButton
              className="h-12 w-[140px]"
              noFill
              title={t("cancel")}
              onClick={() => {
                dispatch(toggleDialog(false));
                dispatch(setActivePage("brandhub"));
              }}
            />
            <PrimaryButton
              className="h-12 w-[140px] ml-2"
              title={t("yes-leave")}
              onClick={() => {
                dispatch(toggleDialog(false));
                dispatch(toggleBrandEditing(false));
                if (clickType === 0) {
                  dispatch(setActivePage("homepage"));
                  dispatch(setActiveItem(loopId?.content_id));
                  ;
                  dispatch(toggleDialog(false));
                  showLoop(loopId);
                } else if (clickType === 1) {
                  ;
                  navigate(`loops`);
                } else if (clickType === 2) {
                  dispatch(setActivePage("inspirations"));
                  dispatch(setActiveItem(""));
                  ;
                  navigate(`inspirations`);
                } else if (clickType === 3) {
                  dispatch(setActivePage("brandhub"));
                  dispatch(setActiveItem(""));
                  ;
                  navigate(`brandhub`);
                } else if (clickType === 4) {
                  setLogoutClosed(false);
                  ;
                  logout();
                }
              }}
            />
          </>
        ),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );

    dispatch(toggleDialog(true));
  };

  //mobile open/close
  //loop detail auto open/close
  //normal show

  return (
    <>
      {showMenu && (
        <div
          ref={(r) => (container = r!)}
          className={`lg:flex bg-menuBg  w-full h-full relative border-r border-border
            ${!(collapsedModeEnabled && !isExpanded && !isMobile())
              ? "lg:w-[240px]"
              : "lg:w-[56px]"
            }
            transition-all
            ${collapsedModeEnabled && !isMobile() ? "absolute" : ""}

             lg:h-screen  lg:max-h-screen overflow-hidden flex-col  z-[1000]`}
        >
          <div className="max-lg:hidden h-11 lg:flex flex-row justify-center my-5 sm:mt-0 items-center">
            <MenuBrandSelector
              selectedBrandId={lastSelectedBrandId}
              logoutClosed={logoutClosed}
              isMenuCollapsed={collapsedModeEnabled}
              BrandSelected={(e) => {
                dispatch(setLastSelectedBrandId(e?.id));
                setIsBrandPopoverOpen(false);
              }}
              tooltip={""}
              isPopoverOpen={isBrandPopoverOpen}
              setIsBrandPopoverOpen={(isOpen) => {
                setIsBrandPopoverOpen(isOpen);
              }}
            />
          </div>

          {!collapsedModeEnabled && (
            <>
              <div className="overflow-scroll scrollbar-hide flex flex-col max-h-[300px] lg:max-h-full">
                {contenListLoading && (
                  <ShimmerTitle
                    line={5}
                    gap={10}
                    variant="secondary"
                    className="w-36 ml-5 mt-5"
                  />
                )}
              </div>
            </>
          )}

          {collapsedModeEnabled && !isExpanded && !isMobile() && (
            <>
              <div className="overflow-scroll scrollbar-hide flex flex-col max-h-[300px] lg:max-h-full">
                {contenListLoading && (
                  <ShimmerTitle
                    line={5}
                    gap={10}
                    variant="secondary"
                    className="w-36 ml-5 mt-5"
                  />
                )}
              </div>
            </>
          )}

          {(!isMobile() ||
            (collapsedModeEnabled && isExpanded && isMobile()) ||
            showMenu) && (
              <div className="flex flex-col h-full p-3  pt-0 pb-[10px] max-lg:fixed max-lg:bottom-0 justify-between mt-3">
                <div className="flex flex-col">

                  <SidebarMenuButton
                    state={
                      activePage === "homepage"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    icon={SvgHome}
                    deactiveColor="#000"
                    tooltip={t("home") ?? ""}
                    onClick={() => {
                      setIsBrandPopoverOpen(false);
                      if (logoutClosed) {
                        dispatch(setActiveItem(undefined));
                        dispatch(setNewContent(undefined));
                        navigate(`homepage`);
                      }
                    }}
                    title={t("home")}
                    collapsedModeIcon={SvgHome}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    className="w-full"
                  />

                  <SidebarMenuButton
                    state={
                      activePage === "home"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    icon={SvgCreativeAdGenerator}
                    deactiveColor="#000"
                    tooltip={t("ad-generator") ?? ""}
                    onClick={() => {
                      setIsBrandPopoverOpen(false);
                      if (logoutClosed) {
                        dispatch(setActiveItem(undefined));
                        dispatch(setNewContent(undefined));
                        navigate(`home`);
                      }
                    }}
                    title={t("ad-generator")}
                    collapsedModeIcon={SvgCreativeAdGenerator}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    className="w-full"
                  />

                  <SidebarMenuButton
                    state={
                      activePage === "marketing-planner"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    icon={SvgMarketPlannerIcon}
                    deactiveColor="#000"
                    onClick={() => {
                      setIsBrandPopoverOpen(false);
                      if (logoutClosed) {
                        dispatch(setActivePage("marketing-planner"));
                        dispatch(setActiveItem(""));
                        navigate(`marketing-planner`);
                      }
                    }}
                    collapsedModeIcon={SvgMarketPlannerIcon}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    title={t("marketing-planner")}
                    className="w-full"
                  />

                  <SidebarMenuButton
                    state={
                      activePage === "content-calendar"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    icon={SvgAIContentManagerIcon}
                    deactiveColor="#000"
                    onClick={() => {
                      setIsBrandPopoverOpen(false);
                      if (logoutClosed) {
                        dispatch(setActivePage("content-calendar"));
                        dispatch(setActiveItem(""));
                        navigate(`content-calendar`);
                      }
                    }}
                    collapsedModeIcon={SvgAIContentManagerIcon}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    title={t("content-calendar")}
                    className="w-full"
                  />
                  <AIMarketingToolsSubMenu
                    onSubItemClick={(type) => {
                      setIsBrandPopoverOpen(false);
                      if (type === "tts") {
                        console.log("clicked");
                        dispatch(setActivePage("text-to-speech"));
                        dispatch(setActiveItem(""));
                        navigate(`text-to-speech`);
                      } else if (type === "background-remover") {
                        dispatch(setActivePage("background-remover"));
                        dispatch(setActiveItem(""));
                        navigate(`background-remover`);
                      }
                    }
                    }
                    deactiveColor="#000"
                    onUncollapse={() => {
                      if (logoutClosed) {
                        dispatch(setCollapsedModeEnabled(false));
                        if (!collapsedModeEnabled && isExpanded) {
                          dispatch(setMenuCollapsed(!isExpanded));
                        }
                      }
                    }}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                  />

                  <SidebarMenuButton
                    state={
                      activePage === "loops"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    icon={Loops}
                    deactiveColor="#000"
                    tooltip={t("contents") ?? ""}
                    onClick={() => {
                      setIsBrandPopoverOpen(false);
                      if (isBrandEditing) {
                        exitWizard(undefined, 1);
                      } else if (!logoutClosed) {
                      } else {
                        navigate(`loops`);
                      }
                    }}
                    title={t("contents")}
                    collapsedModeIcon={Loops}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    className="w-full"
                  />

                </div>
                <div className="flex flex-col">

                  {
                    !collapsedModeEnabled && !isExpanded && !isMobile() && (
                      <CreditLeftCard />
                    )
                  }

                  {!isMobile() && (
                    <SidebarMenuButton
                      state={ButtonState.normal}
                      icon={SvgCollapse}
                      deactiveColor="#000"
                      onClick={() => {
                        setIsBrandPopoverOpen(false);
                        if (logoutClosed) {
                          dispatch(
                            setCollapsedModeEnabled(!collapsedModeEnabled)
                          );
                          if (!collapsedModeEnabled && isExpanded) {
                            dispatch(setMenuCollapsed(!isExpanded));
                          }
                        }
                      }}
                      collapsedModeIcon={SvgCollapseReverse}
                      collapsedMode={
                        collapsedModeEnabled && !isExpanded && !isMobile()
                      }
                      title={t("collapsed-view")}
                      className="w-full transition-all"
                    />
                  )}


                  <SidebarMenuButton
                    icon={SvgSidebarNotificationsIcon}
                    deactiveColor="#000"
                    onClick={() => {
                      setIsBrandPopoverOpen(false);
                      dispatch(toggleNotificationsSidebar());
                    }}
                    collapsedModeIcon={SvgSidebarNotificationsIcon}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    title={t("notifications")}
                    className="w-full"
                  />

                  <SidebarMenuButton
                    state={
                      activePage === "settings"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    icon={SvgSettings}
                    deactiveColor="#000"
                    onClick={() => {
                      setIsBrandPopoverOpen(false);
                      if (isBrandEditing) {
                        exitWizard(undefined, 3);
                      } else if (!logoutClosed) {
                      } else {
                        dispatch(setActivePage("settings"));
                        dispatch(setActiveItem(""));
                        navigate(`settings`);
                      }
                    }}
                    collapsedModeIcon={SvgSettings}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    title={t("settings-socials")}
                    className="w-full"
                  />

                  <SidebarMenuButton
                    state={logoutClosed ? ButtonState.normal : ButtonState.focus}
                    icon={Logout}
                    deactiveColor="#000"
                    collapsedModeIcon={Logout}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    onClick={() => {
                      setIsBrandPopoverOpen(false);
                      if (isBrandEditing) {
                        exitWizard(undefined, 4);
                      } else {
                        setLogoutClosed(false);
                        logout();
                      }
                    }}
                    title={t("logOut")}
                    className="w-full"
                  />


                </div>
              </div>
            )}
        </div>
      )}
      {!showMenu && (
        <div
          ref={(r) => (container = r!)}
          className={`lg:flex bg-menuBg  w-full h-full relative
           lg:w-[56px] lg:h-screen  lg:max-h-screen overflow-hidden flex-col  z-[1000]`}
        >
          <div className="w-full flex items-center h-[56px] min-h-[56px] max-h-[56px]">
            <SvgLogoIcon className="ml-[18px]" />
          </div>
          <hr className="mb-1 -mr-3" style={{ borderColor: "#1F2022" }} />
        </div>
      )}
    </>
  );
}

export default SidebarAlternative;
