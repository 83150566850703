import { dataURLToBlob } from "blob-util";
import { t } from "i18next";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";

import { useLocation, useNavigate } from "react-router-dom";
import SvgBrandHubProductPhotosIcon from "../../../assets/IconComponents/BrandHubProductPhotosIcon";
import UploadVisuals from "../../../components/UploadFile/UploadVisuals";
import UploadFileInfinite from "../../../components/UploadFile/UploadFileInfinite";
import { setNewBrandInfo } from "../../../store/brandSlice";


interface IBrandHubGalleryProps { }

const BrandHubGallery: FunctionComponent<IBrandHubGalleryProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  return (
    <div className="flex flex-col bg-white border-1 rounded-[8px] pb-5 w-full">
      <div className="mt-2 ml-2 flex flex-row w-full items-center">
        <SvgBrandHubProductPhotosIcon />
        <span className="text-H7 text-black ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
          {t("product-photos")}
        </span>
      </div>
      <div className="flex flex-col w-full md:flex-row ml-3">
        <UploadFileInfinite
          size="large"
          uploadedFiles={brandInfo.selectedProductPhotos}
          title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
          description="Upload .jpg or .png files. Max 25mb."
          filesChanged={(f) => {
            {
              dispatch(
                setNewBrandInfo({
                  ...brandInfo,
                  selectedProductPhotos: f.map((k: any) => ({
                    id: k.uploadedId,
                    name: k.name,
                    uploaded: true,
                  })),
                })
              );

            }
          }}
          selectionEnabled={false} />
      </div>
    </div>
  );
};

export default BrandHubGallery;
